import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { createSelectAssignmentIsVariation } from '@glass/common/modules/assignments/redux';
import { AssignmentVariationValue } from '@glass/common/modules/assignments/types';

const useAssignmentIsVariation = (experiment: string, variation?: AssignmentVariationValue) => {
  const selectExperimentAssignmentIs = useMemo(
    () => createSelectAssignmentIsVariation(experiment, variation),
    [experiment, variation],
  );
  return useSelector(selectExperimentAssignmentIs);
};

export default useAssignmentIsVariation;
