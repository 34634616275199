import Typography, { type TypographyProps } from '@glass/web/components/base/Typography';

export const EXTRA_SMALL_FONT_SIZE = 11;

function CaptionTypography({
  children,
  component = 'div',
  fontSize,
  lineHeight = 1.4,
  align,
}: TypographyProps) {
  return (
    <Typography
      align={align}
      component={component}
      lineHeight={lineHeight}
      color="text.secondary"
      fontSize={fontSize}
      variant="caption"
    >
      {children}
    </Typography>
  );
}

export default CaptionTypography;
