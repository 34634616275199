import PropTypes from 'prop-types';
import React from 'react';

import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMATTED,
} from '@glass/common/modules/support/rock';
import SecureServerBadge from '@glass/web/components/Badges/SecureServerBadge';
import CaptionTypography, {
  EXTRA_SMALL_FONT_SIZE,
} from '@glass/web/components/Typography/CaptionTypography';
import useIsDocumentReadyPriorityLoadedIdleInView from '@glass/web/modules/loading/useIsDocumentReadyPriorityLoadedIdleInView';
import { ALL_BREAKPOINTS, DEFAULT_BP, SM } from '@glass/web/modules/theme/breakpointKeys';
import { GREY_50 } from '@glass/web/modules/theme/colors';
import { CENTER } from '@glass/web/modules/theme/constants';
import { FLEX } from '@glass/web/modules/theme/flexConstants';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import Container from '@glass/shared/components/Layout/Container';
import LogoLink from '@glass/shared/components/Layout/Header/LogoLink';
import Link from '@glass/shared/components/Link/Link';
import serverDate from '@glass/shared/modules/datetime/serverDate';

const useStyles = makeStyles()((theme) => ({
  standAlone: {
    backgroundColor: GREY_50,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  container: {
    alignItems: CENTER,
    display: FLEX,
    padding: theme.spacing(2, 0),
  },
  support: {
    textAlign: 'right',
    marginRight: theme.spacing(1),
    '@media (max-width: 475px)': {
      display: 'none',
    },
  },
  copyright: {
    marginRight: 'auto',
    // marginRight: theme.spacing(1),
  },
  badge: {
    alignItems: CENTER,
    display: FLEX,
    [theme.breakpoints.down(SM)]: {
      display: 'none',
    },
  },
  logo: {
    marginTop: -4,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
}));

const COPYRIGHT_TEXT = `© ${new Date(serverDate).getUTCFullYear()} ${
  process.env.NEXT_PUBLIC_BRAND_TITLE
}`;

function MiniFooter({ className, maxWidth, isStandAlone }) {
  // use this to remove the logo from the dom to reduce initial html size
  const { refInView, isDocumentReadyPriorityLoadedIdleInView } =
    useIsDocumentReadyPriorityLoadedIdleInView();
  const { classes, cx } = useStyles();

  return (
    <div ref={refInView} className={cx(isStandAlone ? classes.standAlone : null, className)}>
      <Container noYPadding maxWidth={maxWidth}>
        <div className={classes.container}>
          {isDocumentReadyPriorityLoadedIdleInView ? <LogoLink className={classes.logo} /> : null}
          <div className={classes.copyright}>
            <CaptionTypography fontSize={EXTRA_SMALL_FONT_SIZE}>{COPYRIGHT_TEXT}</CaptionTypography>
            <CaptionTypography fontSize={EXTRA_SMALL_FONT_SIZE}>
              All rights reserved
            </CaptionTypography>
          </div>
          <div className={classes.support}>
            <CaptionTypography fontSize={EXTRA_SMALL_FONT_SIZE}>
              <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
            </CaptionTypography>
            <CaptionTypography fontSize={EXTRA_SMALL_FONT_SIZE}>
              <Link href={`tel:${SUPPORT_PHONE}`}>{SUPPORT_PHONE_FORMATTED}</Link>
            </CaptionTypography>
          </div>
          <div className={classes.badge}>
            <SecureServerBadge />
          </div>
        </div>
      </Container>
    </div>
  );
}

MiniFooter.propTypes = {
  className: PropTypes.string,
  isStandAlone: PropTypes.bool,
  maxWidth: PropTypes.oneOf(ALL_BREAKPOINTS),
};

MiniFooter.defaultProps = {
  maxWidth: DEFAULT_BP,
  isStandAlone: true,
};

export default React.memo(MiniFooter);
