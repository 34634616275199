import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React from 'react';

import Grid from '@glass/web/components/base/Unstable_Grid2';
import { FOOTER_ID } from '@glass/web/modules/content/pages/contentIds';
import useIsDocumentReadyPriorityLoadedIdleInView from '@glass/web/modules/loading/useIsDocumentReadyPriorityLoadedIdleInView';
import { ALL_BREAKPOINTS, DEFAULT_BP } from '@glass/web/modules/theme/breakpointKeys';
import { GREY_200 } from '@glass/web/modules/theme/colors';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import { DEFAULT_SPACING } from '@glass/web/modules/theme/spacing';

import Container from '@glass/shared/components/Layout/Container';
import FooterContactList from '@glass/shared/components/Layout/Footer/FooterContactList';
import FooterIndustryList from '@glass/shared/components/Layout/Footer/FooterIndustryList';
import FooterResumeList from '@glass/shared/components/Layout/Footer/FooterResumeList';
import MiniFooter from '@glass/shared/components/Layout/Footer/MiniFooter';
import useAlternatingBackgroundStyles from '@glass/shared/components/Layout/Footer/useAlternatingBackgroundStyles';
import LoadingSection from '@glass/shared/components/Section/LoadingSection';
import {
  DISABLE_LOADING,
  LAZY_LOADING,
  SSR_LOADING,
} from '@glass/shared/components/Section/loadingMethods';
import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

const DynamicSupportSection = dynamic(
  () => import('@glass/shared/components/Section/SupportSection/SupportSection'),
  {
    loading: LoadingSection,
  },
);

const DynamicResourcesSection = dynamic(
  () => import('@glass/shared/components/Section/ResourcesSection/ResourcesSection'),
  {
    loading: LoadingSection,
  },
);

const useStyles = makeStyles()((theme) => ({
  innerContainer: {
    paddingTop: theme.spacing(DEFAULT_SPACING),
    paddingBottom: theme.spacing(DEFAULT_SPACING),
  },
}));

function Footer({
  maxWidth,
  isSupportPhone,
  resourceSectionLoadingMethod,
  supportSectionLoadingMethod,
}) {
  // we use grey_50 here because it needs to contrast with the default grays (either 50 or 100) and we don't know which will be the last color section and it will definitely won't be 200
  const { classes: alternatingClasses, cx } = useAlternatingBackgroundStyles({
    bgColor: GREY_200,
  });
  const { classes } = useStyles();
  const { refInView, isDocumentReadyPriorityLoadedIdleInView } =
    useIsDocumentReadyPriorityLoadedIdleInView();

  return (
    <footer ref={refInView}>
      {(resourceSectionLoadingMethod === LAZY_LOADING && isDocumentReadyPriorityLoadedIdleInView) ||
      resourceSectionLoadingMethod === SSR_LOADING ? (
        <DynamicResourcesSection
          className={alternatingClasses.section}
          isAlternatingColor={false}
          loadingMethod={resourceSectionLoadingMethod}
        />
      ) : null}
      {(supportSectionLoadingMethod === LAZY_LOADING && isDocumentReadyPriorityLoadedIdleInView) ||
      supportSectionLoadingMethod === SSR_LOADING ? (
        <DynamicSupportSection
          className={alternatingClasses.section}
          isAlternatingColor={false}
          isSupportPhone={isSupportPhone}
        />
      ) : null}
      <div className={alternatingClasses.section}>
        <Container noYPadding maxWidth={maxWidth}>
          <div className={classes.innerContainer}>
            <Grid container maxWidth={maxWidth} spacing={1}>
              <Grid md={4} sm={6} xs={12}>
                <FooterResumeList />
              </Grid>
              <Grid md={4} sm={6} xs={12}>
                <FooterIndustryList />
              </Grid>
              <Grid md={4} sm={6} xs={12}>
                <FooterContactList />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <MiniFooter
        className={cx(alternatingClasses.section, alternatingClasses.lastSection)}
        isStandAlone={false}
        maxWidth={maxWidth}
      />
    </footer>
  );
}

Footer.propTypes = {
  isSupportPhone: PropTypes.bool,
  maxWidth: PropTypes.oneOf(ALL_BREAKPOINTS),
  resourceSectionLoadingMethod: PropTypes.oneOf([DISABLE_LOADING, LAZY_LOADING, SSR_LOADING]),
  supportSectionLoadingMethod: PropTypes.oneOf([DISABLE_LOADING, LAZY_LOADING, SSR_LOADING]),
};

Footer.defaultProps = {
  maxWidth: DEFAULT_BP,
  resourceSectionLoadingMethod: LAZY_LOADING,
  supportSectionLoadingMethod: LAZY_LOADING,
};

export default withTrackingContext(Footer, FOOTER_ID);
