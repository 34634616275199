import Script from 'next/script';
import React from 'react';

import useIsPriorityLoadedIdle from '@glass/web/modules/loading/useIsPriorityLoadedIdle';

const SRC = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_KEY}`;

if (typeof window !== 'undefined') {
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  window._learnq = window._learnq || [];
}

function KlaviyoScript() {
  const isPriorityIdleLoaded = useIsPriorityLoadedIdle({
    enabled: !!process.env.NEXT_PUBLIC_KLAVIYO_KEY,
  });

  if (!process.env.NEXT_PUBLIC_KLAVIYO_KEY || !isPriorityIdleLoaded) {
    return null;
  }

  return (
    <Script
      id="klav"
      defer
      // @ts-ignore
      fetchpriority="low"
      src={SRC}
      strategy="lazyOnload"
    />
  );
}

export default React.memo(KlaviyoScript);
