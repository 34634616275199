import { useMemo } from 'react';

import createSkeletonWidth from '@glass/common/modules/skeleton/createSkeletonWidth';

const useLoadingResources = (numLoading, key = 'slug') => {
  return useMemo(
    () =>
      Array.from({ length: numLoading }, (_, idx) => ({
        [key]: idx.toString(),
        width: createSkeletonWidth(idx),
      })),
    [numLoading, key],
  );
};

export default useLoadingResources;
