import { taxonomyOrigin } from '@glass/env/modules/origins/constants';

import createApolloClient from '@glass/shared/modules/apollo/createApolloClient';

const TAXONOMY_CLIENT_ID = 'taxonomyClient';
const TAXONOMY_SERVER_CLIENT_ID = 'taxonomyServerClient';

/**
 * Make sure to create a new client for every server-side request so that data
 * isn't shared between connections (which would be bad)
 */
const createTaxonomyClient = (id = TAXONOMY_CLIENT_ID) =>
  createApolloClient(undefined, undefined, {
    url: taxonomyOrigin,
    id,
  });

export const createTaxonomyServerClient = () => createTaxonomyClient(TAXONOMY_SERVER_CLIENT_ID);

export type TaxonomyServerClient = ReturnType<typeof createTaxonomyClient>;

export default createTaxonomyClient;
