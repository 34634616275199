import PropTypes from 'prop-types';
import React from 'react';

import goldenRatio from '@glass/common/modules/goldenRatio/goldenRatio';
import useCenterLargeStyles from '@glass/web/components/Typography/useCenterLargeStyles';
import { MD } from '@glass/web/modules/theme/breakpointKeys';
import { CENTER, CENTER_LARGE } from '@glass/web/modules/theme/constants';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import { SMALL_SPACING } from '@glass/web/modules/theme/spacing';

const useStyles = makeStyles()((theme, { spacing: spacingProp }) => {
  const spacing = theme.spacing(spacingProp);
  const smallSpacing = theme.spacing(spacingProp / goldenRatio);
  return {
    root: {
      marginTop: spacing,
      marginBottom: spacing,
      [theme.breakpoints.down(MD)]: {
        marginTop: smallSpacing,
        marginBottom: smallSpacing,
      },
    },
    noMarginBottom: {
      marginBottom: '0px!important',
    },
    noMarginTop: {
      marginTop: '0px!important',
    },
  };
});

export const BOTTOM_EDGE = 'bottom';
export const TOP_EDGE = 'top';
export const BOTH_EDGE = 'both';

function SectionSpacer({ align, children, edge, spacing, className }) {
  const { classes, cx } = useStyles({ spacing });
  const { classes: centerLargeClasses } = useCenterLargeStyles();
  return (
    <div
      className={cx(
        classes.root,
        edge === BOTTOM_EDGE ? classes.noMarginBottom : null,
        edge === TOP_EDGE ? classes.noMarginTop : null,
        edge === BOTH_EDGE ? classes.noMarginTop : null,
        edge === BOTH_EDGE ? classes.noMarginBottom : null,
        align === CENTER ? centerLargeClasses.center : null,
        align === CENTER_LARGE ? centerLargeClasses.centerLarge : null,
        className,
      )}
    >
      {children}
    </div>
  );
}

SectionSpacer.defaultProps = {
  spacing: SMALL_SPACING,
};

SectionSpacer.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  edge: PropTypes.oneOf([BOTTOM_EDGE, TOP_EDGE, null]),
  spacing: PropTypes.number,
};

SectionSpacer.displayName = 'SectionSpacer';

export default SectionSpacer;
