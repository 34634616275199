import PropTypes from 'prop-types';
import React from 'react';

import { inverseGoldenRatio } from '@glass/common/modules/goldenRatio/goldenRatio';
import MuiContainer from '@glass/web/components/base/Container';
import { ALL_BREAKPOINTS, DEFAULT_BP, SM } from '@glass/web/modules/theme/breakpointKeys';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import { DEFAULT_SPACING } from '@glass/web/modules/theme/spacing';

const useStyles = makeStyles()((theme) => ({
  root: {
    [theme.breakpoints.down(SM)]: {
      padding: theme.spacing(1),
    },
    padding: theme.spacing(2),
    // MUI is setting the padding left & padding right inside this media query and it's overriding our custom padding, so adding this media query makes the css selector deeper to gain precedence over MUI's selector
    // https://github.com/rocket-resume/glass/issues/2926
    [theme.breakpoints.up(SM)]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  noYPadding: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  noXPadding: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  fullPage: {
    minHeight: `${inverseGoldenRatio}vh`,
    marginBottom: theme.spacing(DEFAULT_SPACING),
  },
}));

const Container = React.forwardRef(
  ({ children, noYPadding, noXPadding, className, fullPage, maxWidth }, ref) => {
    const { classes, cx } = useStyles();
    return (
      <MuiContainer
        ref={ref}
        className={cx(
          classes.root,
          className,
          noYPadding && classes.noYPadding,
          noXPadding && classes.noXPadding,
          fullPage && classes.fullPage,
        )}
        maxWidth={maxWidth}
      >
        {children}
      </MuiContainer>
    );
  },
);

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fullPage: PropTypes.bool,
  maxWidth: PropTypes.oneOf(ALL_BREAKPOINTS),
  noXPadding: PropTypes.bool,
  noYPadding: PropTypes.bool,
};

Container.defaultProps = {
  maxWidth: DEFAULT_BP,
  noXPadding: false,
  noYPadding: false,
  fullPage: false,
};

export default React.memo(Container);
