import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
  ID: { input: string; output: string; }
  /** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
  String: { input: string; output: string; }
  /** The `Boolean` scalar type represents `true` or `false`. */
  Boolean: { input: boolean; output: boolean; }
  /** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
  Int: { input: number; output: number; }
  /** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](https://en.wikipedia.org/wiki/IEEE_floating_point). */
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export enum AlgorithmTypeOption {
  OrConditions = 'or_conditions',
  Promises = 'promises',
  RawQuery = 'raw_query',
  Transaction = 'transaction'
}

/** Batch payloads from prisma. */
export type BatchPayload = {
  __typename?: 'BatchPayload';
  /** Prisma Batch Payload */
  count: Scalars['Int']['output'];
};

export type Category = {
  __typename?: 'Category';
  childrenCategories: Array<Category>;
  contents: Array<CategoryContent>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  metaData?: Maybe<MetaData>;
  name: Scalars['String']['output'];
  parentCategories: Array<Category>;
  relatedCategories: Array<RelatedCategories>;
  relatedChildrenCategories: Array<RelatedCategories>;
  scores: Array<Score>;
  slug: Scalars['String']['output'];
  sources: Array<CategorySource>;
  status: ContentStatus;
  type: CategoryType;
  updatedAt: Scalars['DateTime']['output'];
};


export type CategoryChildrenCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type CategoryContentsArgs = {
  cursor?: InputMaybe<CategoryContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryContentWhereInput>;
};


export type CategoryParentCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type CategoryRelatedCategoriesArgs = {
  cursor?: InputMaybe<RelatedCategoriesWhereUniqueInput>;
  distinct?: InputMaybe<Array<RelatedCategoriesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RelatedCategoriesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RelatedCategoriesWhereInput>;
};


export type CategoryRelatedChildrenCategoriesArgs = {
  cursor?: InputMaybe<RelatedCategoriesWhereUniqueInput>;
  distinct?: InputMaybe<Array<RelatedCategoriesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RelatedCategoriesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RelatedCategoriesWhereInput>;
};


export type CategoryScoresArgs = {
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
};


export type CategorySourcesArgs = {
  cursor?: InputMaybe<CategorySourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategorySourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategorySourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategorySourceWhereInput>;
};

export type CategoryContent = {
  __typename?: 'CategoryContent';
  category?: Maybe<Category>;
  categoryId: Scalars['String']['output'];
  content?: Maybe<Content>;
  contentId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CategoryContentCategoryIdContentIdCompoundUniqueInput = {
  categoryId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
};

export type CategoryContentCountOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryContentCreateInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutContentsInput>;
  content?: InputMaybe<ContentCreateNestedOneWithoutCategoriesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryContentCreateManyCategoryInput = {
  contentId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryContentCreateManyCategoryInputEnvelope = {
  data: Array<CategoryContentCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategoryContentCreateManyContentInput = {
  categoryId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryContentCreateManyContentInputEnvelope = {
  data: Array<CategoryContentCreateManyContentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategoryContentCreateManyInput = {
  categoryId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryContentCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<CategoryContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryContentCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<CategoryContentCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<CategoryContentCreateManyCategoryInputEnvelope>;
};

export type CategoryContentCreateNestedManyWithoutContentInput = {
  connect?: InputMaybe<Array<CategoryContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryContentCreateOrConnectWithoutContentInput>>;
  create?: InputMaybe<Array<CategoryContentCreateWithoutContentInput>>;
  createMany?: InputMaybe<CategoryContentCreateManyContentInputEnvelope>;
};

export type CategoryContentCreateOrConnectWithoutCategoryInput = {
  create: CategoryContentCreateWithoutCategoryInput;
  where: CategoryContentWhereUniqueInput;
};

export type CategoryContentCreateOrConnectWithoutContentInput = {
  create: CategoryContentCreateWithoutContentInput;
  where: CategoryContentWhereUniqueInput;
};

export type CategoryContentCreateWithoutCategoryInput = {
  content?: InputMaybe<ContentCreateNestedOneWithoutCategoriesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryContentCreateWithoutContentInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutContentsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryContentListRelationFilter = {
  every?: InputMaybe<CategoryContentWhereInput>;
  none?: InputMaybe<CategoryContentWhereInput>;
  some?: InputMaybe<CategoryContentWhereInput>;
};

export type CategoryContentMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryContentMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryContentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CategoryContentOrderByWithAggregationInput = {
  _count?: InputMaybe<CategoryContentCountOrderByAggregateInput>;
  _max?: InputMaybe<CategoryContentMaxOrderByAggregateInput>;
  _min?: InputMaybe<CategoryContentMinOrderByAggregateInput>;
  categoryId?: InputMaybe<SortOrder>;
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryContentOrderByWithRelationInput = {
  category?: InputMaybe<CategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  content?: InputMaybe<ContentOrderByWithRelationInput>;
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum CategoryContentScalarFieldEnum {
  CategoryId = 'categoryId',
  ContentId = 'contentId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type CategoryContentScalarWhereInput = {
  AND?: InputMaybe<Array<CategoryContentScalarWhereInput>>;
  NOT?: InputMaybe<Array<CategoryContentScalarWhereInput>>;
  OR?: InputMaybe<Array<CategoryContentScalarWhereInput>>;
  categoryId?: InputMaybe<StringFilter>;
  contentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CategoryContentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CategoryContentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CategoryContentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CategoryContentScalarWhereWithAggregatesInput>>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  contentId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type CategoryContentUpdateInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutContentsNestedInput>;
  content?: InputMaybe<ContentUpdateOneWithoutCategoriesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryContentUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryContentUpdateManyWithWhereWithoutCategoryInput = {
  data: CategoryContentUpdateManyMutationInput;
  where: CategoryContentScalarWhereInput;
};

export type CategoryContentUpdateManyWithWhereWithoutContentInput = {
  data: CategoryContentUpdateManyMutationInput;
  where: CategoryContentScalarWhereInput;
};

export type CategoryContentUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<CategoryContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryContentCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<CategoryContentCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<CategoryContentCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<CategoryContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryContentWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryContentWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryContentUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<CategoryContentUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<CategoryContentUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type CategoryContentUpdateManyWithoutContentNestedInput = {
  connect?: InputMaybe<Array<CategoryContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryContentCreateOrConnectWithoutContentInput>>;
  create?: InputMaybe<Array<CategoryContentCreateWithoutContentInput>>;
  createMany?: InputMaybe<CategoryContentCreateManyContentInputEnvelope>;
  delete?: InputMaybe<Array<CategoryContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryContentWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryContentWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryContentUpdateWithWhereUniqueWithoutContentInput>>;
  updateMany?: InputMaybe<Array<CategoryContentUpdateManyWithWhereWithoutContentInput>>;
  upsert?: InputMaybe<Array<CategoryContentUpsertWithWhereUniqueWithoutContentInput>>;
};

export type CategoryContentUpdateWithWhereUniqueWithoutCategoryInput = {
  data: CategoryContentUpdateWithoutCategoryInput;
  where: CategoryContentWhereUniqueInput;
};

export type CategoryContentUpdateWithWhereUniqueWithoutContentInput = {
  data: CategoryContentUpdateWithoutContentInput;
  where: CategoryContentWhereUniqueInput;
};

export type CategoryContentUpdateWithoutCategoryInput = {
  content?: InputMaybe<ContentUpdateOneWithoutCategoriesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryContentUpdateWithoutContentInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutContentsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryContentUpsertWithWhereUniqueWithoutCategoryInput = {
  create: CategoryContentCreateWithoutCategoryInput;
  update: CategoryContentUpdateWithoutCategoryInput;
  where: CategoryContentWhereUniqueInput;
};

export type CategoryContentUpsertWithWhereUniqueWithoutContentInput = {
  create: CategoryContentCreateWithoutContentInput;
  update: CategoryContentUpdateWithoutContentInput;
  where: CategoryContentWhereUniqueInput;
};

export type CategoryContentWhereInput = {
  AND?: InputMaybe<Array<CategoryContentWhereInput>>;
  NOT?: InputMaybe<Array<CategoryContentWhereInput>>;
  OR?: InputMaybe<Array<CategoryContentWhereInput>>;
  category?: InputMaybe<CategoryWhereInput>;
  categoryId?: InputMaybe<StringFilter>;
  content?: InputMaybe<ContentWhereInput>;
  contentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CategoryContentWhereUniqueInput = {
  AND?: InputMaybe<Array<CategoryContentWhereInput>>;
  NOT?: InputMaybe<Array<CategoryContentWhereInput>>;
  OR?: InputMaybe<Array<CategoryContentWhereInput>>;
  category?: InputMaybe<CategoryWhereInput>;
  categoryId?: InputMaybe<StringFilter>;
  categoryId_contentId?: InputMaybe<CategoryContentCategoryIdContentIdCompoundUniqueInput>;
  content?: InputMaybe<ContentWhereInput>;
  contentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CategoryCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryCreateInput = {
  childrenCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoriesInput>;
  contents?: InputMaybe<CategoryContentCreateNestedManyWithoutCategoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<MetaDataCreateNestedOneWithoutCategoryInput>;
  name: Scalars['String']['input'];
  parentCategories?: InputMaybe<CategoryCreateNestedManyWithoutChildrenCategoriesInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedByInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedInput>;
  scores?: InputMaybe<ScoreCreateNestedManyWithoutCategoriesInput>;
  slug: Scalars['String']['input'];
  sources?: InputMaybe<CategorySourceCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<ContentStatus>;
  type: CategoryType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  status?: InputMaybe<ContentStatus>;
  type: CategoryType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateNestedManyWithoutChildrenCategoriesInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutChildrenCategoriesInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutChildrenCategoriesInput>>;
};

export type CategoryCreateNestedManyWithoutParentCategoriesInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutParentCategoriesInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutParentCategoriesInput>>;
};

export type CategoryCreateNestedManyWithoutScoresInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutScoresInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutScoresInput>>;
};

export type CategoryCreateNestedOneWithoutContentsInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<CategoryCreateWithoutContentsInput>;
};

export type CategoryCreateNestedOneWithoutMetaDataInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutMetaDataInput>;
  create?: InputMaybe<CategoryCreateWithoutMetaDataInput>;
};

export type CategoryCreateNestedOneWithoutRelatedCategoriesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutRelatedCategoriesInput>;
  create?: InputMaybe<CategoryCreateWithoutRelatedCategoriesInput>;
};

export type CategoryCreateNestedOneWithoutRelatedChildrenCategoriesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutRelatedChildrenCategoriesInput>;
  create?: InputMaybe<CategoryCreateWithoutRelatedChildrenCategoriesInput>;
};

export type CategoryCreateNestedOneWithoutSourcesInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutSourcesInput>;
  create?: InputMaybe<CategoryCreateWithoutSourcesInput>;
};

export type CategoryCreateOrConnectWithoutChildrenCategoriesInput = {
  create: CategoryCreateWithoutChildrenCategoriesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutContentsInput = {
  create: CategoryCreateWithoutContentsInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutMetaDataInput = {
  create: CategoryCreateWithoutMetaDataInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutParentCategoriesInput = {
  create: CategoryCreateWithoutParentCategoriesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutRelatedCategoriesInput = {
  create: CategoryCreateWithoutRelatedCategoriesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutRelatedChildrenCategoriesInput = {
  create: CategoryCreateWithoutRelatedChildrenCategoriesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutScoresInput = {
  create: CategoryCreateWithoutScoresInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateOrConnectWithoutSourcesInput = {
  create: CategoryCreateWithoutSourcesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryCreateWithoutChildrenCategoriesInput = {
  contents?: InputMaybe<CategoryContentCreateNestedManyWithoutCategoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<MetaDataCreateNestedOneWithoutCategoryInput>;
  name: Scalars['String']['input'];
  parentCategories?: InputMaybe<CategoryCreateNestedManyWithoutChildrenCategoriesInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedByInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedInput>;
  scores?: InputMaybe<ScoreCreateNestedManyWithoutCategoriesInput>;
  slug: Scalars['String']['input'];
  sources?: InputMaybe<CategorySourceCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<ContentStatus>;
  type: CategoryType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateWithoutContentsInput = {
  childrenCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoriesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<MetaDataCreateNestedOneWithoutCategoryInput>;
  name: Scalars['String']['input'];
  parentCategories?: InputMaybe<CategoryCreateNestedManyWithoutChildrenCategoriesInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedByInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedInput>;
  scores?: InputMaybe<ScoreCreateNestedManyWithoutCategoriesInput>;
  slug: Scalars['String']['input'];
  sources?: InputMaybe<CategorySourceCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<ContentStatus>;
  type: CategoryType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateWithoutMetaDataInput = {
  childrenCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoriesInput>;
  contents?: InputMaybe<CategoryContentCreateNestedManyWithoutCategoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parentCategories?: InputMaybe<CategoryCreateNestedManyWithoutChildrenCategoriesInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedByInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedInput>;
  scores?: InputMaybe<ScoreCreateNestedManyWithoutCategoriesInput>;
  slug: Scalars['String']['input'];
  sources?: InputMaybe<CategorySourceCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<ContentStatus>;
  type: CategoryType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateWithoutParentCategoriesInput = {
  childrenCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoriesInput>;
  contents?: InputMaybe<CategoryContentCreateNestedManyWithoutCategoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<MetaDataCreateNestedOneWithoutCategoryInput>;
  name: Scalars['String']['input'];
  relatedCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedByInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedInput>;
  scores?: InputMaybe<ScoreCreateNestedManyWithoutCategoriesInput>;
  slug: Scalars['String']['input'];
  sources?: InputMaybe<CategorySourceCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<ContentStatus>;
  type: CategoryType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateWithoutRelatedCategoriesInput = {
  childrenCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoriesInput>;
  contents?: InputMaybe<CategoryContentCreateNestedManyWithoutCategoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<MetaDataCreateNestedOneWithoutCategoryInput>;
  name: Scalars['String']['input'];
  parentCategories?: InputMaybe<CategoryCreateNestedManyWithoutChildrenCategoriesInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedInput>;
  scores?: InputMaybe<ScoreCreateNestedManyWithoutCategoriesInput>;
  slug: Scalars['String']['input'];
  sources?: InputMaybe<CategorySourceCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<ContentStatus>;
  type: CategoryType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateWithoutRelatedChildrenCategoriesInput = {
  childrenCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoriesInput>;
  contents?: InputMaybe<CategoryContentCreateNestedManyWithoutCategoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<MetaDataCreateNestedOneWithoutCategoryInput>;
  name: Scalars['String']['input'];
  parentCategories?: InputMaybe<CategoryCreateNestedManyWithoutChildrenCategoriesInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedByInput>;
  scores?: InputMaybe<ScoreCreateNestedManyWithoutCategoriesInput>;
  slug: Scalars['String']['input'];
  sources?: InputMaybe<CategorySourceCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<ContentStatus>;
  type: CategoryType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateWithoutScoresInput = {
  childrenCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoriesInput>;
  contents?: InputMaybe<CategoryContentCreateNestedManyWithoutCategoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<MetaDataCreateNestedOneWithoutCategoryInput>;
  name: Scalars['String']['input'];
  parentCategories?: InputMaybe<CategoryCreateNestedManyWithoutChildrenCategoriesInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedByInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedInput>;
  slug: Scalars['String']['input'];
  sources?: InputMaybe<CategorySourceCreateNestedManyWithoutCategoryInput>;
  status?: InputMaybe<ContentStatus>;
  type: CategoryType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryCreateWithoutSourcesInput = {
  childrenCategories?: InputMaybe<CategoryCreateNestedManyWithoutParentCategoriesInput>;
  contents?: InputMaybe<CategoryContentCreateNestedManyWithoutCategoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<MetaDataCreateNestedOneWithoutCategoryInput>;
  name: Scalars['String']['input'];
  parentCategories?: InputMaybe<CategoryCreateNestedManyWithoutChildrenCategoriesInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedByInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesCreateNestedManyWithoutRelatedInput>;
  scores?: InputMaybe<ScoreCreateNestedManyWithoutCategoriesInput>;
  slug: Scalars['String']['input'];
  status?: InputMaybe<ContentStatus>;
  type: CategoryType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryListRelationFilter = {
  every?: InputMaybe<CategoryWhereInput>;
  none?: InputMaybe<CategoryWhereInput>;
  some?: InputMaybe<CategoryWhereInput>;
};

export type CategoryMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryNullableRelationFilter = {
  is?: InputMaybe<CategoryWhereInput>;
  isNot?: InputMaybe<CategoryWhereInput>;
};

export type CategoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CategoryOrderByWithAggregationInput = {
  _count?: InputMaybe<CategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<CategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<CategoryMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryOrderByWithRelationInput = {
  childrenCategories?: InputMaybe<CategoryOrderByRelationAggregateInput>;
  contents?: InputMaybe<CategoryContentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  metaData?: InputMaybe<MetaDataOrderByWithRelationInput>;
  name?: InputMaybe<SortOrder>;
  parentCategories?: InputMaybe<CategoryOrderByRelationAggregateInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesOrderByRelationAggregateInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesOrderByRelationAggregateInput>;
  scores?: InputMaybe<ScoreOrderByRelationAggregateInput>;
  slug?: InputMaybe<SortOrder>;
  sources?: InputMaybe<CategorySourceOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryRelationFilter = {
  is?: InputMaybe<CategoryWhereInput>;
  isNot?: InputMaybe<CategoryWhereInput>;
};

export enum CategoryScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Slug = 'slug',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type CategoryScalarWhereInput = {
  AND?: InputMaybe<Array<CategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<CategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<CategoryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumContentStatusFilter>;
  type?: InputMaybe<EnumCategoryTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumContentStatusWithAggregatesFilter>;
  type?: InputMaybe<EnumCategoryTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type CategorySlugTypeCompoundUniqueInput = {
  slug: Scalars['String']['input'];
  type: CategoryType;
};

export type CategorySource = {
  __typename?: 'CategorySource';
  category?: Maybe<Category>;
  categoryId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  source?: Maybe<Source>;
  sourceId: Scalars['String']['output'];
  sourcedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CategorySourceCategoryIdSourceIdCompoundUniqueInput = {
  categoryId: Scalars['String']['input'];
  sourceId: Scalars['String']['input'];
};

export type CategorySourceCountOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  sourceId?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategorySourceCreateInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutSourcesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  source?: InputMaybe<SourceCreateNestedOneWithoutCategoriesInput>;
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategorySourceCreateManyCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceId: Scalars['String']['input'];
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategorySourceCreateManyCategoryInputEnvelope = {
  data: Array<CategorySourceCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategorySourceCreateManyInput = {
  categoryId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceId: Scalars['String']['input'];
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategorySourceCreateManySourceInput = {
  categoryId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategorySourceCreateManySourceInputEnvelope = {
  data: Array<CategorySourceCreateManySourceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategorySourceCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<CategorySourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategorySourceCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<CategorySourceCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<CategorySourceCreateManyCategoryInputEnvelope>;
};

export type CategorySourceCreateNestedManyWithoutSourceInput = {
  connect?: InputMaybe<Array<CategorySourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategorySourceCreateOrConnectWithoutSourceInput>>;
  create?: InputMaybe<Array<CategorySourceCreateWithoutSourceInput>>;
  createMany?: InputMaybe<CategorySourceCreateManySourceInputEnvelope>;
};

export type CategorySourceCreateOrConnectWithoutCategoryInput = {
  create: CategorySourceCreateWithoutCategoryInput;
  where: CategorySourceWhereUniqueInput;
};

export type CategorySourceCreateOrConnectWithoutSourceInput = {
  create: CategorySourceCreateWithoutSourceInput;
  where: CategorySourceWhereUniqueInput;
};

export type CategorySourceCreateWithoutCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  source?: InputMaybe<SourceCreateNestedOneWithoutCategoriesInput>;
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategorySourceCreateWithoutSourceInput = {
  category?: InputMaybe<CategoryCreateNestedOneWithoutSourcesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategorySourceListRelationFilter = {
  every?: InputMaybe<CategorySourceWhereInput>;
  none?: InputMaybe<CategorySourceWhereInput>;
  some?: InputMaybe<CategorySourceWhereInput>;
};

export type CategorySourceMaxOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  sourceId?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategorySourceMinOrderByAggregateInput = {
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  sourceId?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategorySourceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CategorySourceOrderByWithAggregationInput = {
  _count?: InputMaybe<CategorySourceCountOrderByAggregateInput>;
  _max?: InputMaybe<CategorySourceMaxOrderByAggregateInput>;
  _min?: InputMaybe<CategorySourceMinOrderByAggregateInput>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  sourceId?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategorySourceOrderByWithRelationInput = {
  category?: InputMaybe<CategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  source?: InputMaybe<SourceOrderByWithRelationInput>;
  sourceId?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum CategorySourceScalarFieldEnum {
  CategoryId = 'categoryId',
  CreatedAt = 'createdAt',
  SourceId = 'sourceId',
  SourcedAt = 'sourcedAt',
  UpdatedAt = 'updatedAt'
}

export type CategorySourceScalarWhereInput = {
  AND?: InputMaybe<Array<CategorySourceScalarWhereInput>>;
  NOT?: InputMaybe<Array<CategorySourceScalarWhereInput>>;
  OR?: InputMaybe<Array<CategorySourceScalarWhereInput>>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  sourceId?: InputMaybe<StringFilter>;
  sourcedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CategorySourceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CategorySourceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CategorySourceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CategorySourceScalarWhereWithAggregatesInput>>;
  categoryId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  sourceId?: InputMaybe<StringWithAggregatesFilter>;
  sourcedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type CategorySourceUpdateInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutSourcesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  source?: InputMaybe<SourceUpdateOneWithoutCategoriesNestedInput>;
  sourcedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategorySourceUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sourcedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategorySourceUpdateManyWithWhereWithoutCategoryInput = {
  data: CategorySourceUpdateManyMutationInput;
  where: CategorySourceScalarWhereInput;
};

export type CategorySourceUpdateManyWithWhereWithoutSourceInput = {
  data: CategorySourceUpdateManyMutationInput;
  where: CategorySourceScalarWhereInput;
};

export type CategorySourceUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<CategorySourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategorySourceCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<CategorySourceCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<CategorySourceCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<CategorySourceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategorySourceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategorySourceWhereUniqueInput>>;
  set?: InputMaybe<Array<CategorySourceWhereUniqueInput>>;
  update?: InputMaybe<Array<CategorySourceUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<CategorySourceUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<CategorySourceUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type CategorySourceUpdateManyWithoutSourceNestedInput = {
  connect?: InputMaybe<Array<CategorySourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategorySourceCreateOrConnectWithoutSourceInput>>;
  create?: InputMaybe<Array<CategorySourceCreateWithoutSourceInput>>;
  createMany?: InputMaybe<CategorySourceCreateManySourceInputEnvelope>;
  delete?: InputMaybe<Array<CategorySourceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategorySourceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategorySourceWhereUniqueInput>>;
  set?: InputMaybe<Array<CategorySourceWhereUniqueInput>>;
  update?: InputMaybe<Array<CategorySourceUpdateWithWhereUniqueWithoutSourceInput>>;
  updateMany?: InputMaybe<Array<CategorySourceUpdateManyWithWhereWithoutSourceInput>>;
  upsert?: InputMaybe<Array<CategorySourceUpsertWithWhereUniqueWithoutSourceInput>>;
};

export type CategorySourceUpdateWithWhereUniqueWithoutCategoryInput = {
  data: CategorySourceUpdateWithoutCategoryInput;
  where: CategorySourceWhereUniqueInput;
};

export type CategorySourceUpdateWithWhereUniqueWithoutSourceInput = {
  data: CategorySourceUpdateWithoutSourceInput;
  where: CategorySourceWhereUniqueInput;
};

export type CategorySourceUpdateWithoutCategoryInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  source?: InputMaybe<SourceUpdateOneWithoutCategoriesNestedInput>;
  sourcedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategorySourceUpdateWithoutSourceInput = {
  category?: InputMaybe<CategoryUpdateOneWithoutSourcesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sourcedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategorySourceUpsertWithWhereUniqueWithoutCategoryInput = {
  create: CategorySourceCreateWithoutCategoryInput;
  update: CategorySourceUpdateWithoutCategoryInput;
  where: CategorySourceWhereUniqueInput;
};

export type CategorySourceUpsertWithWhereUniqueWithoutSourceInput = {
  create: CategorySourceCreateWithoutSourceInput;
  update: CategorySourceUpdateWithoutSourceInput;
  where: CategorySourceWhereUniqueInput;
};

export type CategorySourceWhereInput = {
  AND?: InputMaybe<Array<CategorySourceWhereInput>>;
  NOT?: InputMaybe<Array<CategorySourceWhereInput>>;
  OR?: InputMaybe<Array<CategorySourceWhereInput>>;
  category?: InputMaybe<CategoryWhereInput>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  source?: InputMaybe<SourceWhereInput>;
  sourceId?: InputMaybe<StringFilter>;
  sourcedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CategorySourceWhereUniqueInput = {
  AND?: InputMaybe<Array<CategorySourceWhereInput>>;
  NOT?: InputMaybe<Array<CategorySourceWhereInput>>;
  OR?: InputMaybe<Array<CategorySourceWhereInput>>;
  category?: InputMaybe<CategoryWhereInput>;
  categoryId?: InputMaybe<StringFilter>;
  categoryId_sourceId?: InputMaybe<CategorySourceCategoryIdSourceIdCompoundUniqueInput>;
  createdAt?: InputMaybe<DateTimeFilter>;
  source?: InputMaybe<SourceWhereInput>;
  sourceId?: InputMaybe<StringFilter>;
  sourcedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum CategoryType {
  Classification = 'CLASSIFICATION',
  FieldOfStudy = 'FIELD_OF_STUDY',
  Industry = 'INDUSTRY',
  Jobtitle = 'JOBTITLE'
}

export type CategoryUpdateInput = {
  childrenCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoriesNestedInput>;
  contents?: InputMaybe<CategoryContentUpdateManyWithoutCategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metaData?: InputMaybe<MetaDataUpdateOneWithoutCategoryNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategories?: InputMaybe<CategoryUpdateManyWithoutChildrenCategoriesNestedInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedByNestedInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedNestedInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sources?: InputMaybe<CategorySourceUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCategoryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCategoryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateManyWithWhereWithoutChildrenCategoriesInput = {
  data: CategoryUpdateManyMutationInput;
  where: CategoryScalarWhereInput;
};

export type CategoryUpdateManyWithWhereWithoutParentCategoriesInput = {
  data: CategoryUpdateManyMutationInput;
  where: CategoryScalarWhereInput;
};

export type CategoryUpdateManyWithWhereWithoutScoresInput = {
  data: CategoryUpdateManyMutationInput;
  where: CategoryScalarWhereInput;
};

export type CategoryUpdateManyWithoutChildrenCategoriesNestedInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutChildrenCategoriesInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutChildrenCategoriesInput>>;
  delete?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryUpdateWithWhereUniqueWithoutChildrenCategoriesInput>>;
  updateMany?: InputMaybe<Array<CategoryUpdateManyWithWhereWithoutChildrenCategoriesInput>>;
  upsert?: InputMaybe<Array<CategoryUpsertWithWhereUniqueWithoutChildrenCategoriesInput>>;
};

export type CategoryUpdateManyWithoutParentCategoriesNestedInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutParentCategoriesInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutParentCategoriesInput>>;
  delete?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryUpdateWithWhereUniqueWithoutParentCategoriesInput>>;
  updateMany?: InputMaybe<Array<CategoryUpdateManyWithWhereWithoutParentCategoriesInput>>;
  upsert?: InputMaybe<Array<CategoryUpsertWithWhereUniqueWithoutParentCategoriesInput>>;
};

export type CategoryUpdateManyWithoutScoresNestedInput = {
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CategoryCreateOrConnectWithoutScoresInput>>;
  create?: InputMaybe<Array<CategoryCreateWithoutScoresInput>>;
  delete?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<CategoryUpdateWithWhereUniqueWithoutScoresInput>>;
  updateMany?: InputMaybe<Array<CategoryUpdateManyWithWhereWithoutScoresInput>>;
  upsert?: InputMaybe<Array<CategoryUpsertWithWhereUniqueWithoutScoresInput>>;
};

export type CategoryUpdateOneRequiredWithoutRelatedCategoriesNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutRelatedCategoriesInput>;
  create?: InputMaybe<CategoryCreateWithoutRelatedCategoriesInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutRelatedCategoriesInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutRelatedCategoriesInput>;
};

export type CategoryUpdateOneRequiredWithoutRelatedChildrenCategoriesNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutRelatedChildrenCategoriesInput>;
  create?: InputMaybe<CategoryCreateWithoutRelatedChildrenCategoriesInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutRelatedChildrenCategoriesInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutRelatedChildrenCategoriesInput>;
};

export type CategoryUpdateOneWithoutContentsNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<CategoryCreateWithoutContentsInput>;
  delete?: InputMaybe<CategoryWhereInput>;
  disconnect?: InputMaybe<CategoryWhereInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutContentsInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutContentsInput>;
};

export type CategoryUpdateOneWithoutMetaDataNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutMetaDataInput>;
  create?: InputMaybe<CategoryCreateWithoutMetaDataInput>;
  delete?: InputMaybe<CategoryWhereInput>;
  disconnect?: InputMaybe<CategoryWhereInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutMetaDataInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutMetaDataInput>;
};

export type CategoryUpdateOneWithoutSourcesNestedInput = {
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CategoryCreateOrConnectWithoutSourcesInput>;
  create?: InputMaybe<CategoryCreateWithoutSourcesInput>;
  delete?: InputMaybe<CategoryWhereInput>;
  disconnect?: InputMaybe<CategoryWhereInput>;
  update?: InputMaybe<CategoryUpdateToOneWithWhereWithoutSourcesInput>;
  upsert?: InputMaybe<CategoryUpsertWithoutSourcesInput>;
};

export type CategoryUpdateToOneWithWhereWithoutContentsInput = {
  data: CategoryUpdateWithoutContentsInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateToOneWithWhereWithoutMetaDataInput = {
  data: CategoryUpdateWithoutMetaDataInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateToOneWithWhereWithoutRelatedCategoriesInput = {
  data: CategoryUpdateWithoutRelatedCategoriesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateToOneWithWhereWithoutRelatedChildrenCategoriesInput = {
  data: CategoryUpdateWithoutRelatedChildrenCategoriesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateToOneWithWhereWithoutSourcesInput = {
  data: CategoryUpdateWithoutSourcesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpdateWithWhereUniqueWithoutChildrenCategoriesInput = {
  data: CategoryUpdateWithoutChildrenCategoriesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpdateWithWhereUniqueWithoutParentCategoriesInput = {
  data: CategoryUpdateWithoutParentCategoriesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpdateWithWhereUniqueWithoutScoresInput = {
  data: CategoryUpdateWithoutScoresInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpdateWithoutChildrenCategoriesInput = {
  contents?: InputMaybe<CategoryContentUpdateManyWithoutCategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metaData?: InputMaybe<MetaDataUpdateOneWithoutCategoryNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategories?: InputMaybe<CategoryUpdateManyWithoutChildrenCategoriesNestedInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedByNestedInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedNestedInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sources?: InputMaybe<CategorySourceUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCategoryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutContentsInput = {
  childrenCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoriesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metaData?: InputMaybe<MetaDataUpdateOneWithoutCategoryNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategories?: InputMaybe<CategoryUpdateManyWithoutChildrenCategoriesNestedInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedByNestedInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedNestedInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sources?: InputMaybe<CategorySourceUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCategoryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutMetaDataInput = {
  childrenCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoriesNestedInput>;
  contents?: InputMaybe<CategoryContentUpdateManyWithoutCategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategories?: InputMaybe<CategoryUpdateManyWithoutChildrenCategoriesNestedInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedByNestedInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedNestedInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sources?: InputMaybe<CategorySourceUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCategoryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutParentCategoriesInput = {
  childrenCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoriesNestedInput>;
  contents?: InputMaybe<CategoryContentUpdateManyWithoutCategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metaData?: InputMaybe<MetaDataUpdateOneWithoutCategoryNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedByNestedInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedNestedInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sources?: InputMaybe<CategorySourceUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCategoryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutRelatedCategoriesInput = {
  childrenCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoriesNestedInput>;
  contents?: InputMaybe<CategoryContentUpdateManyWithoutCategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metaData?: InputMaybe<MetaDataUpdateOneWithoutCategoryNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategories?: InputMaybe<CategoryUpdateManyWithoutChildrenCategoriesNestedInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedNestedInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sources?: InputMaybe<CategorySourceUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCategoryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutRelatedChildrenCategoriesInput = {
  childrenCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoriesNestedInput>;
  contents?: InputMaybe<CategoryContentUpdateManyWithoutCategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metaData?: InputMaybe<MetaDataUpdateOneWithoutCategoryNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategories?: InputMaybe<CategoryUpdateManyWithoutChildrenCategoriesNestedInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedByNestedInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sources?: InputMaybe<CategorySourceUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCategoryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutScoresInput = {
  childrenCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoriesNestedInput>;
  contents?: InputMaybe<CategoryContentUpdateManyWithoutCategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metaData?: InputMaybe<MetaDataUpdateOneWithoutCategoryNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategories?: InputMaybe<CategoryUpdateManyWithoutChildrenCategoriesNestedInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedByNestedInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sources?: InputMaybe<CategorySourceUpdateManyWithoutCategoryNestedInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCategoryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpdateWithoutSourcesInput = {
  childrenCategories?: InputMaybe<CategoryUpdateManyWithoutParentCategoriesNestedInput>;
  contents?: InputMaybe<CategoryContentUpdateManyWithoutCategoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metaData?: InputMaybe<MetaDataUpdateOneWithoutCategoryNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCategories?: InputMaybe<CategoryUpdateManyWithoutChildrenCategoriesNestedInput>;
  relatedCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedByNestedInput>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesUpdateManyWithoutRelatedNestedInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutCategoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCategoryTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CategoryUpsertWithWhereUniqueWithoutChildrenCategoriesInput = {
  create: CategoryCreateWithoutChildrenCategoriesInput;
  update: CategoryUpdateWithoutChildrenCategoriesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpsertWithWhereUniqueWithoutParentCategoriesInput = {
  create: CategoryCreateWithoutParentCategoriesInput;
  update: CategoryUpdateWithoutParentCategoriesInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpsertWithWhereUniqueWithoutScoresInput = {
  create: CategoryCreateWithoutScoresInput;
  update: CategoryUpdateWithoutScoresInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryUpsertWithoutContentsInput = {
  create: CategoryCreateWithoutContentsInput;
  update: CategoryUpdateWithoutContentsInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpsertWithoutMetaDataInput = {
  create: CategoryCreateWithoutMetaDataInput;
  update: CategoryUpdateWithoutMetaDataInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpsertWithoutRelatedCategoriesInput = {
  create: CategoryCreateWithoutRelatedCategoriesInput;
  update: CategoryUpdateWithoutRelatedCategoriesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpsertWithoutRelatedChildrenCategoriesInput = {
  create: CategoryCreateWithoutRelatedChildrenCategoriesInput;
  update: CategoryUpdateWithoutRelatedChildrenCategoriesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryUpsertWithoutSourcesInput = {
  create: CategoryCreateWithoutSourcesInput;
  update: CategoryUpdateWithoutSourcesInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryWhereInput = {
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  childrenCategories?: InputMaybe<CategoryListRelationFilter>;
  contents?: InputMaybe<CategoryContentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  metaData?: InputMaybe<MetaDataWhereInput>;
  name?: InputMaybe<StringFilter>;
  parentCategories?: InputMaybe<CategoryListRelationFilter>;
  relatedCategories?: InputMaybe<RelatedCategoriesListRelationFilter>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesListRelationFilter>;
  scores?: InputMaybe<ScoreListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  sources?: InputMaybe<CategorySourceListRelationFilter>;
  status?: InputMaybe<EnumContentStatusFilter>;
  type?: InputMaybe<EnumCategoryTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CategoryWhereUniqueInput = {
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  childrenCategories?: InputMaybe<CategoryListRelationFilter>;
  contents?: InputMaybe<CategoryContentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<MetaDataWhereInput>;
  name?: InputMaybe<StringFilter>;
  parentCategories?: InputMaybe<CategoryListRelationFilter>;
  relatedCategories?: InputMaybe<RelatedCategoriesListRelationFilter>;
  relatedChildrenCategories?: InputMaybe<RelatedCategoriesListRelationFilter>;
  scores?: InputMaybe<ScoreListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  slug_type?: InputMaybe<CategorySlugTypeCompoundUniqueInput>;
  sources?: InputMaybe<CategorySourceListRelationFilter>;
  status?: InputMaybe<EnumContentStatusFilter>;
  type?: InputMaybe<EnumCategoryTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Content = {
  __typename?: 'Content';
  categories: Array<CategoryContent>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  scores: Array<Score>;
  slug: Scalars['String']['output'];
  sources: Array<ContentSource>;
  status: ContentStatus;
  text: Scalars['String']['output'];
  type: ContentType;
  updatedAt: Scalars['DateTime']['output'];
};


export type ContentCategoriesArgs = {
  cursor?: InputMaybe<CategoryContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryContentWhereInput>;
};


export type ContentScoresArgs = {
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
};


export type ContentSourcesArgs = {
  cursor?: InputMaybe<ContentSourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentSourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentSourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentSourceWhereInput>;
};

export type ContentCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ContentCreateInput = {
  categories?: InputMaybe<CategoryContentCreateNestedManyWithoutContentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  scores?: InputMaybe<ScoreCreateNestedManyWithoutContentsInput>;
  slug: Scalars['String']['input'];
  sources?: InputMaybe<ContentSourceCreateNestedManyWithoutContentInput>;
  status?: InputMaybe<ContentStatus>;
  text: Scalars['String']['input'];
  type: ContentType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  status?: InputMaybe<ContentStatus>;
  text: Scalars['String']['input'];
  type: ContentType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentCreateNestedManyWithoutScoresInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutScoresInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutScoresInput>>;
};

export type ContentCreateNestedOneWithoutCategoriesInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutCategoriesInput>;
  create?: InputMaybe<ContentCreateWithoutCategoriesInput>;
};

export type ContentCreateNestedOneWithoutSourcesInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutSourcesInput>;
  create?: InputMaybe<ContentCreateWithoutSourcesInput>;
};

export type ContentCreateOrConnectWithoutCategoriesInput = {
  create: ContentCreateWithoutCategoriesInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateOrConnectWithoutScoresInput = {
  create: ContentCreateWithoutScoresInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateOrConnectWithoutSourcesInput = {
  create: ContentCreateWithoutSourcesInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateWithoutCategoriesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  scores?: InputMaybe<ScoreCreateNestedManyWithoutContentsInput>;
  slug: Scalars['String']['input'];
  sources?: InputMaybe<ContentSourceCreateNestedManyWithoutContentInput>;
  status?: InputMaybe<ContentStatus>;
  text: Scalars['String']['input'];
  type: ContentType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentCreateWithoutScoresInput = {
  categories?: InputMaybe<CategoryContentCreateNestedManyWithoutContentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  sources?: InputMaybe<ContentSourceCreateNestedManyWithoutContentInput>;
  status?: InputMaybe<ContentStatus>;
  text: Scalars['String']['input'];
  type: ContentType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentCreateWithoutSourcesInput = {
  categories?: InputMaybe<CategoryContentCreateNestedManyWithoutContentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  scores?: InputMaybe<ScoreCreateNestedManyWithoutContentsInput>;
  slug: Scalars['String']['input'];
  status?: InputMaybe<ContentStatus>;
  text: Scalars['String']['input'];
  type: ContentType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentListRelationFilter = {
  every?: InputMaybe<ContentWhereInput>;
  none?: InputMaybe<ContentWhereInput>;
  some?: InputMaybe<ContentWhereInput>;
};

export type ContentMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ContentMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ContentNullableRelationFilter = {
  is?: InputMaybe<ContentWhereInput>;
  isNot?: InputMaybe<ContentWhereInput>;
};

export type ContentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ContentOrderByWithAggregationInput = {
  _count?: InputMaybe<ContentCountOrderByAggregateInput>;
  _max?: InputMaybe<ContentMaxOrderByAggregateInput>;
  _min?: InputMaybe<ContentMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ContentOrderByWithRelationInput = {
  categories?: InputMaybe<CategoryContentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  scores?: InputMaybe<ScoreOrderByRelationAggregateInput>;
  slug?: InputMaybe<SortOrder>;
  sources?: InputMaybe<ContentSourceOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ContentScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Slug = 'slug',
  Status = 'status',
  Text = 'text',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type ContentScalarWhereInput = {
  AND?: InputMaybe<Array<ContentScalarWhereInput>>;
  NOT?: InputMaybe<Array<ContentScalarWhereInput>>;
  OR?: InputMaybe<Array<ContentScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumContentStatusFilter>;
  text?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumContentTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ContentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ContentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ContentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ContentScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<EnumContentStatusWithAggregatesFilter>;
  text?: InputMaybe<StringWithAggregatesFilter>;
  type?: InputMaybe<EnumContentTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ContentSource = {
  __typename?: 'ContentSource';
  content?: Maybe<Content>;
  contentId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  source?: Maybe<Source>;
  sourceId: Scalars['String']['output'];
  sourcedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ContentSourceContentIdSourceIdCompoundUniqueInput = {
  contentId: Scalars['String']['input'];
  sourceId: Scalars['String']['input'];
};

export type ContentSourceCountOrderByAggregateInput = {
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  sourceId?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ContentSourceCreateInput = {
  content?: InputMaybe<ContentCreateNestedOneWithoutSourcesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  source?: InputMaybe<SourceCreateNestedOneWithoutContentsInput>;
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentSourceCreateManyContentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceId: Scalars['String']['input'];
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentSourceCreateManyContentInputEnvelope = {
  data: Array<ContentSourceCreateManyContentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentSourceCreateManyInput = {
  contentId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourceId: Scalars['String']['input'];
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentSourceCreateManySourceInput = {
  contentId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentSourceCreateManySourceInputEnvelope = {
  data: Array<ContentSourceCreateManySourceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentSourceCreateNestedManyWithoutContentInput = {
  connect?: InputMaybe<Array<ContentSourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentSourceCreateOrConnectWithoutContentInput>>;
  create?: InputMaybe<Array<ContentSourceCreateWithoutContentInput>>;
  createMany?: InputMaybe<ContentSourceCreateManyContentInputEnvelope>;
};

export type ContentSourceCreateNestedManyWithoutSourceInput = {
  connect?: InputMaybe<Array<ContentSourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentSourceCreateOrConnectWithoutSourceInput>>;
  create?: InputMaybe<Array<ContentSourceCreateWithoutSourceInput>>;
  createMany?: InputMaybe<ContentSourceCreateManySourceInputEnvelope>;
};

export type ContentSourceCreateOrConnectWithoutContentInput = {
  create: ContentSourceCreateWithoutContentInput;
  where: ContentSourceWhereUniqueInput;
};

export type ContentSourceCreateOrConnectWithoutSourceInput = {
  create: ContentSourceCreateWithoutSourceInput;
  where: ContentSourceWhereUniqueInput;
};

export type ContentSourceCreateWithoutContentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  source?: InputMaybe<SourceCreateNestedOneWithoutContentsInput>;
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentSourceCreateWithoutSourceInput = {
  content?: InputMaybe<ContentCreateNestedOneWithoutSourcesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentSourceListRelationFilter = {
  every?: InputMaybe<ContentSourceWhereInput>;
  none?: InputMaybe<ContentSourceWhereInput>;
  some?: InputMaybe<ContentSourceWhereInput>;
};

export type ContentSourceMaxOrderByAggregateInput = {
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  sourceId?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ContentSourceMinOrderByAggregateInput = {
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  sourceId?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ContentSourceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ContentSourceOrderByWithAggregationInput = {
  _count?: InputMaybe<ContentSourceCountOrderByAggregateInput>;
  _max?: InputMaybe<ContentSourceMaxOrderByAggregateInput>;
  _min?: InputMaybe<ContentSourceMinOrderByAggregateInput>;
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  sourceId?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ContentSourceOrderByWithRelationInput = {
  content?: InputMaybe<ContentOrderByWithRelationInput>;
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  source?: InputMaybe<SourceOrderByWithRelationInput>;
  sourceId?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ContentSourceScalarFieldEnum {
  ContentId = 'contentId',
  CreatedAt = 'createdAt',
  SourceId = 'sourceId',
  SourcedAt = 'sourcedAt',
  UpdatedAt = 'updatedAt'
}

export type ContentSourceScalarWhereInput = {
  AND?: InputMaybe<Array<ContentSourceScalarWhereInput>>;
  NOT?: InputMaybe<Array<ContentSourceScalarWhereInput>>;
  OR?: InputMaybe<Array<ContentSourceScalarWhereInput>>;
  contentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  sourceId?: InputMaybe<StringFilter>;
  sourcedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ContentSourceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ContentSourceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ContentSourceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ContentSourceScalarWhereWithAggregatesInput>>;
  contentId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  sourceId?: InputMaybe<StringWithAggregatesFilter>;
  sourcedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ContentSourceUpdateInput = {
  content?: InputMaybe<ContentUpdateOneWithoutSourcesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  source?: InputMaybe<SourceUpdateOneWithoutContentsNestedInput>;
  sourcedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContentSourceUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sourcedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContentSourceUpdateManyWithWhereWithoutContentInput = {
  data: ContentSourceUpdateManyMutationInput;
  where: ContentSourceScalarWhereInput;
};

export type ContentSourceUpdateManyWithWhereWithoutSourceInput = {
  data: ContentSourceUpdateManyMutationInput;
  where: ContentSourceScalarWhereInput;
};

export type ContentSourceUpdateManyWithoutContentNestedInput = {
  connect?: InputMaybe<Array<ContentSourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentSourceCreateOrConnectWithoutContentInput>>;
  create?: InputMaybe<Array<ContentSourceCreateWithoutContentInput>>;
  createMany?: InputMaybe<ContentSourceCreateManyContentInputEnvelope>;
  delete?: InputMaybe<Array<ContentSourceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentSourceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentSourceWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentSourceWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentSourceUpdateWithWhereUniqueWithoutContentInput>>;
  updateMany?: InputMaybe<Array<ContentSourceUpdateManyWithWhereWithoutContentInput>>;
  upsert?: InputMaybe<Array<ContentSourceUpsertWithWhereUniqueWithoutContentInput>>;
};

export type ContentSourceUpdateManyWithoutSourceNestedInput = {
  connect?: InputMaybe<Array<ContentSourceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentSourceCreateOrConnectWithoutSourceInput>>;
  create?: InputMaybe<Array<ContentSourceCreateWithoutSourceInput>>;
  createMany?: InputMaybe<ContentSourceCreateManySourceInputEnvelope>;
  delete?: InputMaybe<Array<ContentSourceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentSourceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentSourceWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentSourceWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentSourceUpdateWithWhereUniqueWithoutSourceInput>>;
  updateMany?: InputMaybe<Array<ContentSourceUpdateManyWithWhereWithoutSourceInput>>;
  upsert?: InputMaybe<Array<ContentSourceUpsertWithWhereUniqueWithoutSourceInput>>;
};

export type ContentSourceUpdateWithWhereUniqueWithoutContentInput = {
  data: ContentSourceUpdateWithoutContentInput;
  where: ContentSourceWhereUniqueInput;
};

export type ContentSourceUpdateWithWhereUniqueWithoutSourceInput = {
  data: ContentSourceUpdateWithoutSourceInput;
  where: ContentSourceWhereUniqueInput;
};

export type ContentSourceUpdateWithoutContentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  source?: InputMaybe<SourceUpdateOneWithoutContentsNestedInput>;
  sourcedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContentSourceUpdateWithoutSourceInput = {
  content?: InputMaybe<ContentUpdateOneWithoutSourcesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sourcedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContentSourceUpsertWithWhereUniqueWithoutContentInput = {
  create: ContentSourceCreateWithoutContentInput;
  update: ContentSourceUpdateWithoutContentInput;
  where: ContentSourceWhereUniqueInput;
};

export type ContentSourceUpsertWithWhereUniqueWithoutSourceInput = {
  create: ContentSourceCreateWithoutSourceInput;
  update: ContentSourceUpdateWithoutSourceInput;
  where: ContentSourceWhereUniqueInput;
};

export type ContentSourceWhereInput = {
  AND?: InputMaybe<Array<ContentSourceWhereInput>>;
  NOT?: InputMaybe<Array<ContentSourceWhereInput>>;
  OR?: InputMaybe<Array<ContentSourceWhereInput>>;
  content?: InputMaybe<ContentWhereInput>;
  contentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  source?: InputMaybe<SourceWhereInput>;
  sourceId?: InputMaybe<StringFilter>;
  sourcedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ContentSourceWhereUniqueInput = {
  AND?: InputMaybe<Array<ContentSourceWhereInput>>;
  NOT?: InputMaybe<Array<ContentSourceWhereInput>>;
  OR?: InputMaybe<Array<ContentSourceWhereInput>>;
  content?: InputMaybe<ContentWhereInput>;
  contentId?: InputMaybe<StringFilter>;
  contentId_sourceId?: InputMaybe<ContentSourceContentIdSourceIdCompoundUniqueInput>;
  createdAt?: InputMaybe<DateTimeFilter>;
  source?: InputMaybe<SourceWhereInput>;
  sourceId?: InputMaybe<StringFilter>;
  sourcedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ContentStatus {
  Excluded = 'EXCLUDED',
  NeedsReview = 'NEEDS_REVIEW',
  Pending = 'PENDING',
  Published = 'PUBLISHED'
}

export type ContentTextTypeCompoundUniqueInput = {
  text: Scalars['String']['input'];
  type: ContentType;
};

export enum ContentType {
  Accomplishment = 'ACCOMPLISHMENT',
  Affiliation = 'AFFILIATION',
  Certification = 'CERTIFICATION',
  Experience = 'EXPERIENCE',
  Skill = 'SKILL',
  Summary = 'SUMMARY'
}

export enum ContentTypeOption {
  Accomplishment = 'ACCOMPLISHMENT',
  Affiliation = 'AFFILIATION',
  Certification = 'CERTIFICATION',
  Experience = 'EXPERIENCE',
  Skill = 'SKILL',
  Summary = 'SUMMARY'
}

export type ContentUpdateInput = {
  categories?: InputMaybe<CategoryContentUpdateManyWithoutContentNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutContentsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sources?: InputMaybe<ContentSourceUpdateManyWithoutContentNestedInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumContentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContentUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumContentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContentUpdateManyWithWhereWithoutScoresInput = {
  data: ContentUpdateManyMutationInput;
  where: ContentScalarWhereInput;
};

export type ContentUpdateManyWithoutScoresNestedInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutScoresInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutScoresInput>>;
  delete?: InputMaybe<Array<ContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentUpdateWithWhereUniqueWithoutScoresInput>>;
  updateMany?: InputMaybe<Array<ContentUpdateManyWithWhereWithoutScoresInput>>;
  upsert?: InputMaybe<Array<ContentUpsertWithWhereUniqueWithoutScoresInput>>;
};

export type ContentUpdateOneWithoutCategoriesNestedInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutCategoriesInput>;
  create?: InputMaybe<ContentCreateWithoutCategoriesInput>;
  delete?: InputMaybe<ContentWhereInput>;
  disconnect?: InputMaybe<ContentWhereInput>;
  update?: InputMaybe<ContentUpdateToOneWithWhereWithoutCategoriesInput>;
  upsert?: InputMaybe<ContentUpsertWithoutCategoriesInput>;
};

export type ContentUpdateOneWithoutSourcesNestedInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutSourcesInput>;
  create?: InputMaybe<ContentCreateWithoutSourcesInput>;
  delete?: InputMaybe<ContentWhereInput>;
  disconnect?: InputMaybe<ContentWhereInput>;
  update?: InputMaybe<ContentUpdateToOneWithWhereWithoutSourcesInput>;
  upsert?: InputMaybe<ContentUpsertWithoutSourcesInput>;
};

export type ContentUpdateToOneWithWhereWithoutCategoriesInput = {
  data: ContentUpdateWithoutCategoriesInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type ContentUpdateToOneWithWhereWithoutSourcesInput = {
  data: ContentUpdateWithoutSourcesInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type ContentUpdateWithWhereUniqueWithoutScoresInput = {
  data: ContentUpdateWithoutScoresInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpdateWithoutCategoriesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutContentsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sources?: InputMaybe<ContentSourceUpdateManyWithoutContentNestedInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumContentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContentUpdateWithoutScoresInput = {
  categories?: InputMaybe<CategoryContentUpdateManyWithoutContentNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  sources?: InputMaybe<ContentSourceUpdateManyWithoutContentNestedInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumContentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContentUpdateWithoutSourcesInput = {
  categories?: InputMaybe<CategoryContentUpdateManyWithoutContentNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  scores?: InputMaybe<ScoreUpdateManyWithoutContentsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumContentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumContentTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContentUpsertWithWhereUniqueWithoutScoresInput = {
  create: ContentCreateWithoutScoresInput;
  update: ContentUpdateWithoutScoresInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpsertWithoutCategoriesInput = {
  create: ContentCreateWithoutCategoriesInput;
  update: ContentUpdateWithoutCategoriesInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type ContentUpsertWithoutSourcesInput = {
  create: ContentCreateWithoutSourcesInput;
  update: ContentUpdateWithoutSourcesInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type ContentWhereInput = {
  AND?: InputMaybe<Array<ContentWhereInput>>;
  NOT?: InputMaybe<Array<ContentWhereInput>>;
  OR?: InputMaybe<Array<ContentWhereInput>>;
  categories?: InputMaybe<CategoryContentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  scores?: InputMaybe<ScoreListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  sources?: InputMaybe<ContentSourceListRelationFilter>;
  status?: InputMaybe<EnumContentStatusFilter>;
  text?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumContentTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ContentWhereUniqueInput = {
  AND?: InputMaybe<Array<ContentWhereInput>>;
  NOT?: InputMaybe<Array<ContentWhereInput>>;
  OR?: InputMaybe<Array<ContentWhereInput>>;
  categories?: InputMaybe<CategoryContentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  scores?: InputMaybe<ScoreListRelationFilter>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sources?: InputMaybe<ContentSourceListRelationFilter>;
  status?: InputMaybe<EnumContentStatusFilter>;
  text?: InputMaybe<StringFilter>;
  text_type?: InputMaybe<ContentTextTypeCompoundUniqueInput>;
  type?: InputMaybe<EnumContentTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type EnumCategoryTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CategoryType>;
};

export type EnumCategoryTypeFilter = {
  equals?: InputMaybe<CategoryType>;
  in?: InputMaybe<Array<CategoryType>>;
  not?: InputMaybe<CategoryType>;
  notIn?: InputMaybe<Array<CategoryType>>;
};

export type EnumCategoryTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCategoryTypeFilter>;
  _min?: InputMaybe<NestedEnumCategoryTypeFilter>;
  equals?: InputMaybe<CategoryType>;
  in?: InputMaybe<Array<CategoryType>>;
  not?: InputMaybe<CategoryType>;
  notIn?: InputMaybe<Array<CategoryType>>;
};

export type EnumContentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ContentStatus>;
};

export type EnumContentStatusFilter = {
  equals?: InputMaybe<ContentStatus>;
  in?: InputMaybe<Array<ContentStatus>>;
  not?: InputMaybe<ContentStatus>;
  notIn?: InputMaybe<Array<ContentStatus>>;
};

export type EnumContentStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumContentStatusFilter>;
  _min?: InputMaybe<NestedEnumContentStatusFilter>;
  equals?: InputMaybe<ContentStatus>;
  in?: InputMaybe<Array<ContentStatus>>;
  not?: InputMaybe<ContentStatus>;
  notIn?: InputMaybe<Array<ContentStatus>>;
};

export type EnumContentTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ContentType>;
};

export type EnumContentTypeFilter = {
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  not?: InputMaybe<ContentType>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type EnumContentTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumContentTypeFilter>;
  _min?: InputMaybe<NestedEnumContentTypeFilter>;
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  not?: InputMaybe<ContentType>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type EnumRelationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<RelationType>;
};

export type EnumRelationTypeFilter = {
  equals?: InputMaybe<RelationType>;
  in?: InputMaybe<Array<RelationType>>;
  not?: InputMaybe<RelationType>;
  notIn?: InputMaybe<Array<RelationType>>;
};

export type EnumRelationTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRelationTypeFilter>;
  _min?: InputMaybe<NestedEnumRelationTypeFilter>;
  equals?: InputMaybe<RelationType>;
  in?: InputMaybe<Array<RelationType>>;
  not?: InputMaybe<RelationType>;
  notIn?: InputMaybe<Array<RelationType>>;
};

export type EnumScoreTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ScoreType>;
};

export type EnumScoreTypeFilter = {
  equals?: InputMaybe<ScoreType>;
  in?: InputMaybe<Array<ScoreType>>;
  not?: InputMaybe<ScoreType>;
  notIn?: InputMaybe<Array<ScoreType>>;
};

export type EnumScoreTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumScoreTypeFilter>;
  _min?: InputMaybe<NestedEnumScoreTypeFilter>;
  equals?: InputMaybe<ScoreType>;
  in?: InputMaybe<Array<ScoreType>>;
  not?: InputMaybe<ScoreType>;
  notIn?: InputMaybe<Array<ScoreType>>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type MetaData = {
  __typename?: 'MetaData';
  canonical?: Maybe<MetaData>;
  canonicalChildren: Array<MetaData>;
  canonicalId?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  redirect?: Maybe<MetaData>;
  redirectChildren: Array<MetaData>;
  redirectId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type MetaDataCanonicalChildrenArgs = {
  cursor?: InputMaybe<MetaDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<MetaDataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MetaDataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetaDataWhereInput>;
};


export type MetaDataRedirectChildrenArgs = {
  cursor?: InputMaybe<MetaDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<MetaDataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MetaDataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetaDataWhereInput>;
};

export type MetaDataCountOrderByAggregateInput = {
  canonicalId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  redirectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MetaDataCreateInput = {
  canonical?: InputMaybe<MetaDataCreateNestedOneWithoutCanonicalChildrenInput>;
  canonicalChildren?: InputMaybe<MetaDataCreateNestedManyWithoutCanonicalInput>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutMetaDataInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  redirect?: InputMaybe<MetaDataCreateNestedOneWithoutRedirectChildrenInput>;
  redirectChildren?: InputMaybe<MetaDataCreateNestedManyWithoutRedirectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MetaDataCreateManyCanonicalInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  redirectId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MetaDataCreateManyCanonicalInputEnvelope = {
  data: Array<MetaDataCreateManyCanonicalInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetaDataCreateManyInput = {
  canonicalId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  redirectId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MetaDataCreateManyRedirectInput = {
  canonicalId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MetaDataCreateManyRedirectInputEnvelope = {
  data: Array<MetaDataCreateManyRedirectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetaDataCreateNestedManyWithoutCanonicalInput = {
  connect?: InputMaybe<Array<MetaDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MetaDataCreateOrConnectWithoutCanonicalInput>>;
  create?: InputMaybe<Array<MetaDataCreateWithoutCanonicalInput>>;
  createMany?: InputMaybe<MetaDataCreateManyCanonicalInputEnvelope>;
};

export type MetaDataCreateNestedManyWithoutRedirectInput = {
  connect?: InputMaybe<Array<MetaDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MetaDataCreateOrConnectWithoutRedirectInput>>;
  create?: InputMaybe<Array<MetaDataCreateWithoutRedirectInput>>;
  createMany?: InputMaybe<MetaDataCreateManyRedirectInputEnvelope>;
};

export type MetaDataCreateNestedOneWithoutCanonicalChildrenInput = {
  connect?: InputMaybe<MetaDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MetaDataCreateOrConnectWithoutCanonicalChildrenInput>;
  create?: InputMaybe<MetaDataCreateWithoutCanonicalChildrenInput>;
};

export type MetaDataCreateNestedOneWithoutCategoryInput = {
  connect?: InputMaybe<MetaDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MetaDataCreateOrConnectWithoutCategoryInput>;
  create?: InputMaybe<MetaDataCreateWithoutCategoryInput>;
};

export type MetaDataCreateNestedOneWithoutRedirectChildrenInput = {
  connect?: InputMaybe<MetaDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MetaDataCreateOrConnectWithoutRedirectChildrenInput>;
  create?: InputMaybe<MetaDataCreateWithoutRedirectChildrenInput>;
};

export type MetaDataCreateOrConnectWithoutCanonicalChildrenInput = {
  create: MetaDataCreateWithoutCanonicalChildrenInput;
  where: MetaDataWhereUniqueInput;
};

export type MetaDataCreateOrConnectWithoutCanonicalInput = {
  create: MetaDataCreateWithoutCanonicalInput;
  where: MetaDataWhereUniqueInput;
};

export type MetaDataCreateOrConnectWithoutCategoryInput = {
  create: MetaDataCreateWithoutCategoryInput;
  where: MetaDataWhereUniqueInput;
};

export type MetaDataCreateOrConnectWithoutRedirectChildrenInput = {
  create: MetaDataCreateWithoutRedirectChildrenInput;
  where: MetaDataWhereUniqueInput;
};

export type MetaDataCreateOrConnectWithoutRedirectInput = {
  create: MetaDataCreateWithoutRedirectInput;
  where: MetaDataWhereUniqueInput;
};

export type MetaDataCreateWithoutCanonicalChildrenInput = {
  canonical?: InputMaybe<MetaDataCreateNestedOneWithoutCanonicalChildrenInput>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutMetaDataInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  redirect?: InputMaybe<MetaDataCreateNestedOneWithoutRedirectChildrenInput>;
  redirectChildren?: InputMaybe<MetaDataCreateNestedManyWithoutRedirectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MetaDataCreateWithoutCanonicalInput = {
  canonicalChildren?: InputMaybe<MetaDataCreateNestedManyWithoutCanonicalInput>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutMetaDataInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  redirect?: InputMaybe<MetaDataCreateNestedOneWithoutRedirectChildrenInput>;
  redirectChildren?: InputMaybe<MetaDataCreateNestedManyWithoutRedirectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MetaDataCreateWithoutCategoryInput = {
  canonical?: InputMaybe<MetaDataCreateNestedOneWithoutCanonicalChildrenInput>;
  canonicalChildren?: InputMaybe<MetaDataCreateNestedManyWithoutCanonicalInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  redirect?: InputMaybe<MetaDataCreateNestedOneWithoutRedirectChildrenInput>;
  redirectChildren?: InputMaybe<MetaDataCreateNestedManyWithoutRedirectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MetaDataCreateWithoutRedirectChildrenInput = {
  canonical?: InputMaybe<MetaDataCreateNestedOneWithoutCanonicalChildrenInput>;
  canonicalChildren?: InputMaybe<MetaDataCreateNestedManyWithoutCanonicalInput>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutMetaDataInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  redirect?: InputMaybe<MetaDataCreateNestedOneWithoutRedirectChildrenInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MetaDataCreateWithoutRedirectInput = {
  canonical?: InputMaybe<MetaDataCreateNestedOneWithoutCanonicalChildrenInput>;
  canonicalChildren?: InputMaybe<MetaDataCreateNestedManyWithoutCanonicalInput>;
  category?: InputMaybe<CategoryCreateNestedOneWithoutMetaDataInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  redirectChildren?: InputMaybe<MetaDataCreateNestedManyWithoutRedirectInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MetaDataListRelationFilter = {
  every?: InputMaybe<MetaDataWhereInput>;
  none?: InputMaybe<MetaDataWhereInput>;
  some?: InputMaybe<MetaDataWhereInput>;
};

export type MetaDataMaxOrderByAggregateInput = {
  canonicalId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  redirectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MetaDataMinOrderByAggregateInput = {
  canonicalId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  redirectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MetaDataNullableRelationFilter = {
  is?: InputMaybe<MetaDataWhereInput>;
  isNot?: InputMaybe<MetaDataWhereInput>;
};

export type MetaDataOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MetaDataOrderByWithAggregationInput = {
  _count?: InputMaybe<MetaDataCountOrderByAggregateInput>;
  _max?: InputMaybe<MetaDataMaxOrderByAggregateInput>;
  _min?: InputMaybe<MetaDataMinOrderByAggregateInput>;
  canonicalId?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  redirectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MetaDataOrderByWithRelationInput = {
  canonical?: InputMaybe<MetaDataOrderByWithRelationInput>;
  canonicalChildren?: InputMaybe<MetaDataOrderByRelationAggregateInput>;
  canonicalId?: InputMaybe<SortOrder>;
  category?: InputMaybe<CategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  redirect?: InputMaybe<MetaDataOrderByWithRelationInput>;
  redirectChildren?: InputMaybe<MetaDataOrderByRelationAggregateInput>;
  redirectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum MetaDataScalarFieldEnum {
  CanonicalId = 'canonicalId',
  CategoryId = 'categoryId',
  CreatedAt = 'createdAt',
  Id = 'id',
  RedirectId = 'redirectId',
  UpdatedAt = 'updatedAt'
}

export type MetaDataScalarWhereInput = {
  AND?: InputMaybe<Array<MetaDataScalarWhereInput>>;
  NOT?: InputMaybe<Array<MetaDataScalarWhereInput>>;
  OR?: InputMaybe<Array<MetaDataScalarWhereInput>>;
  canonicalId?: InputMaybe<StringNullableFilter>;
  categoryId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  redirectId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MetaDataScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MetaDataScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MetaDataScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MetaDataScalarWhereWithAggregatesInput>>;
  canonicalId?: InputMaybe<StringNullableWithAggregatesFilter>;
  categoryId?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  redirectId?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type MetaDataUpdateInput = {
  canonical?: InputMaybe<MetaDataUpdateOneWithoutCanonicalChildrenNestedInput>;
  canonicalChildren?: InputMaybe<MetaDataUpdateManyWithoutCanonicalNestedInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutMetaDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirect?: InputMaybe<MetaDataUpdateOneWithoutRedirectChildrenNestedInput>;
  redirectChildren?: InputMaybe<MetaDataUpdateManyWithoutRedirectNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MetaDataUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MetaDataUpdateManyWithWhereWithoutCanonicalInput = {
  data: MetaDataUpdateManyMutationInput;
  where: MetaDataScalarWhereInput;
};

export type MetaDataUpdateManyWithWhereWithoutRedirectInput = {
  data: MetaDataUpdateManyMutationInput;
  where: MetaDataScalarWhereInput;
};

export type MetaDataUpdateManyWithoutCanonicalNestedInput = {
  connect?: InputMaybe<Array<MetaDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MetaDataCreateOrConnectWithoutCanonicalInput>>;
  create?: InputMaybe<Array<MetaDataCreateWithoutCanonicalInput>>;
  createMany?: InputMaybe<MetaDataCreateManyCanonicalInputEnvelope>;
  delete?: InputMaybe<Array<MetaDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MetaDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MetaDataWhereUniqueInput>>;
  set?: InputMaybe<Array<MetaDataWhereUniqueInput>>;
  update?: InputMaybe<Array<MetaDataUpdateWithWhereUniqueWithoutCanonicalInput>>;
  updateMany?: InputMaybe<Array<MetaDataUpdateManyWithWhereWithoutCanonicalInput>>;
  upsert?: InputMaybe<Array<MetaDataUpsertWithWhereUniqueWithoutCanonicalInput>>;
};

export type MetaDataUpdateManyWithoutRedirectNestedInput = {
  connect?: InputMaybe<Array<MetaDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MetaDataCreateOrConnectWithoutRedirectInput>>;
  create?: InputMaybe<Array<MetaDataCreateWithoutRedirectInput>>;
  createMany?: InputMaybe<MetaDataCreateManyRedirectInputEnvelope>;
  delete?: InputMaybe<Array<MetaDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MetaDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MetaDataWhereUniqueInput>>;
  set?: InputMaybe<Array<MetaDataWhereUniqueInput>>;
  update?: InputMaybe<Array<MetaDataUpdateWithWhereUniqueWithoutRedirectInput>>;
  updateMany?: InputMaybe<Array<MetaDataUpdateManyWithWhereWithoutRedirectInput>>;
  upsert?: InputMaybe<Array<MetaDataUpsertWithWhereUniqueWithoutRedirectInput>>;
};

export type MetaDataUpdateOneWithoutCanonicalChildrenNestedInput = {
  connect?: InputMaybe<MetaDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MetaDataCreateOrConnectWithoutCanonicalChildrenInput>;
  create?: InputMaybe<MetaDataCreateWithoutCanonicalChildrenInput>;
  delete?: InputMaybe<MetaDataWhereInput>;
  disconnect?: InputMaybe<MetaDataWhereInput>;
  update?: InputMaybe<MetaDataUpdateToOneWithWhereWithoutCanonicalChildrenInput>;
  upsert?: InputMaybe<MetaDataUpsertWithoutCanonicalChildrenInput>;
};

export type MetaDataUpdateOneWithoutCategoryNestedInput = {
  connect?: InputMaybe<MetaDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MetaDataCreateOrConnectWithoutCategoryInput>;
  create?: InputMaybe<MetaDataCreateWithoutCategoryInput>;
  delete?: InputMaybe<MetaDataWhereInput>;
  disconnect?: InputMaybe<MetaDataWhereInput>;
  update?: InputMaybe<MetaDataUpdateToOneWithWhereWithoutCategoryInput>;
  upsert?: InputMaybe<MetaDataUpsertWithoutCategoryInput>;
};

export type MetaDataUpdateOneWithoutRedirectChildrenNestedInput = {
  connect?: InputMaybe<MetaDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MetaDataCreateOrConnectWithoutRedirectChildrenInput>;
  create?: InputMaybe<MetaDataCreateWithoutRedirectChildrenInput>;
  delete?: InputMaybe<MetaDataWhereInput>;
  disconnect?: InputMaybe<MetaDataWhereInput>;
  update?: InputMaybe<MetaDataUpdateToOneWithWhereWithoutRedirectChildrenInput>;
  upsert?: InputMaybe<MetaDataUpsertWithoutRedirectChildrenInput>;
};

export type MetaDataUpdateToOneWithWhereWithoutCanonicalChildrenInput = {
  data: MetaDataUpdateWithoutCanonicalChildrenInput;
  where?: InputMaybe<MetaDataWhereInput>;
};

export type MetaDataUpdateToOneWithWhereWithoutCategoryInput = {
  data: MetaDataUpdateWithoutCategoryInput;
  where?: InputMaybe<MetaDataWhereInput>;
};

export type MetaDataUpdateToOneWithWhereWithoutRedirectChildrenInput = {
  data: MetaDataUpdateWithoutRedirectChildrenInput;
  where?: InputMaybe<MetaDataWhereInput>;
};

export type MetaDataUpdateWithWhereUniqueWithoutCanonicalInput = {
  data: MetaDataUpdateWithoutCanonicalInput;
  where: MetaDataWhereUniqueInput;
};

export type MetaDataUpdateWithWhereUniqueWithoutRedirectInput = {
  data: MetaDataUpdateWithoutRedirectInput;
  where: MetaDataWhereUniqueInput;
};

export type MetaDataUpdateWithoutCanonicalChildrenInput = {
  canonical?: InputMaybe<MetaDataUpdateOneWithoutCanonicalChildrenNestedInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutMetaDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirect?: InputMaybe<MetaDataUpdateOneWithoutRedirectChildrenNestedInput>;
  redirectChildren?: InputMaybe<MetaDataUpdateManyWithoutRedirectNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MetaDataUpdateWithoutCanonicalInput = {
  canonicalChildren?: InputMaybe<MetaDataUpdateManyWithoutCanonicalNestedInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutMetaDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirect?: InputMaybe<MetaDataUpdateOneWithoutRedirectChildrenNestedInput>;
  redirectChildren?: InputMaybe<MetaDataUpdateManyWithoutRedirectNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MetaDataUpdateWithoutCategoryInput = {
  canonical?: InputMaybe<MetaDataUpdateOneWithoutCanonicalChildrenNestedInput>;
  canonicalChildren?: InputMaybe<MetaDataUpdateManyWithoutCanonicalNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirect?: InputMaybe<MetaDataUpdateOneWithoutRedirectChildrenNestedInput>;
  redirectChildren?: InputMaybe<MetaDataUpdateManyWithoutRedirectNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MetaDataUpdateWithoutRedirectChildrenInput = {
  canonical?: InputMaybe<MetaDataUpdateOneWithoutCanonicalChildrenNestedInput>;
  canonicalChildren?: InputMaybe<MetaDataUpdateManyWithoutCanonicalNestedInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutMetaDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirect?: InputMaybe<MetaDataUpdateOneWithoutRedirectChildrenNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MetaDataUpdateWithoutRedirectInput = {
  canonical?: InputMaybe<MetaDataUpdateOneWithoutCanonicalChildrenNestedInput>;
  canonicalChildren?: InputMaybe<MetaDataUpdateManyWithoutCanonicalNestedInput>;
  category?: InputMaybe<CategoryUpdateOneWithoutMetaDataNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  redirectChildren?: InputMaybe<MetaDataUpdateManyWithoutRedirectNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MetaDataUpsertWithWhereUniqueWithoutCanonicalInput = {
  create: MetaDataCreateWithoutCanonicalInput;
  update: MetaDataUpdateWithoutCanonicalInput;
  where: MetaDataWhereUniqueInput;
};

export type MetaDataUpsertWithWhereUniqueWithoutRedirectInput = {
  create: MetaDataCreateWithoutRedirectInput;
  update: MetaDataUpdateWithoutRedirectInput;
  where: MetaDataWhereUniqueInput;
};

export type MetaDataUpsertWithoutCanonicalChildrenInput = {
  create: MetaDataCreateWithoutCanonicalChildrenInput;
  update: MetaDataUpdateWithoutCanonicalChildrenInput;
  where?: InputMaybe<MetaDataWhereInput>;
};

export type MetaDataUpsertWithoutCategoryInput = {
  create: MetaDataCreateWithoutCategoryInput;
  update: MetaDataUpdateWithoutCategoryInput;
  where?: InputMaybe<MetaDataWhereInput>;
};

export type MetaDataUpsertWithoutRedirectChildrenInput = {
  create: MetaDataCreateWithoutRedirectChildrenInput;
  update: MetaDataUpdateWithoutRedirectChildrenInput;
  where?: InputMaybe<MetaDataWhereInput>;
};

export type MetaDataWhereInput = {
  AND?: InputMaybe<Array<MetaDataWhereInput>>;
  NOT?: InputMaybe<Array<MetaDataWhereInput>>;
  OR?: InputMaybe<Array<MetaDataWhereInput>>;
  canonical?: InputMaybe<MetaDataWhereInput>;
  canonicalChildren?: InputMaybe<MetaDataListRelationFilter>;
  canonicalId?: InputMaybe<StringNullableFilter>;
  category?: InputMaybe<CategoryWhereInput>;
  categoryId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  redirect?: InputMaybe<MetaDataWhereInput>;
  redirectChildren?: InputMaybe<MetaDataListRelationFilter>;
  redirectId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MetaDataWhereUniqueInput = {
  AND?: InputMaybe<Array<MetaDataWhereInput>>;
  NOT?: InputMaybe<Array<MetaDataWhereInput>>;
  OR?: InputMaybe<Array<MetaDataWhereInput>>;
  canonical?: InputMaybe<MetaDataWhereInput>;
  canonicalChildren?: InputMaybe<MetaDataListRelationFilter>;
  canonicalId?: InputMaybe<StringNullableFilter>;
  category?: InputMaybe<CategoryWhereInput>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  redirect?: InputMaybe<MetaDataWhereInput>;
  redirectChildren?: InputMaybe<MetaDataListRelationFilter>;
  redirectId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedEnumCategoryTypeFilter = {
  equals?: InputMaybe<CategoryType>;
  in?: InputMaybe<Array<CategoryType>>;
  not?: InputMaybe<CategoryType>;
  notIn?: InputMaybe<Array<CategoryType>>;
};

export type NestedEnumCategoryTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCategoryTypeFilter>;
  _min?: InputMaybe<NestedEnumCategoryTypeFilter>;
  equals?: InputMaybe<CategoryType>;
  in?: InputMaybe<Array<CategoryType>>;
  not?: InputMaybe<CategoryType>;
  notIn?: InputMaybe<Array<CategoryType>>;
};

export type NestedEnumContentStatusFilter = {
  equals?: InputMaybe<ContentStatus>;
  in?: InputMaybe<Array<ContentStatus>>;
  not?: InputMaybe<ContentStatus>;
  notIn?: InputMaybe<Array<ContentStatus>>;
};

export type NestedEnumContentStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumContentStatusFilter>;
  _min?: InputMaybe<NestedEnumContentStatusFilter>;
  equals?: InputMaybe<ContentStatus>;
  in?: InputMaybe<Array<ContentStatus>>;
  not?: InputMaybe<ContentStatus>;
  notIn?: InputMaybe<Array<ContentStatus>>;
};

export type NestedEnumContentTypeFilter = {
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  not?: InputMaybe<ContentType>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type NestedEnumContentTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumContentTypeFilter>;
  _min?: InputMaybe<NestedEnumContentTypeFilter>;
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  not?: InputMaybe<ContentType>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type NestedEnumRelationTypeFilter = {
  equals?: InputMaybe<RelationType>;
  in?: InputMaybe<Array<RelationType>>;
  not?: InputMaybe<RelationType>;
  notIn?: InputMaybe<Array<RelationType>>;
};

export type NestedEnumRelationTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRelationTypeFilter>;
  _min?: InputMaybe<NestedEnumRelationTypeFilter>;
  equals?: InputMaybe<RelationType>;
  in?: InputMaybe<Array<RelationType>>;
  not?: InputMaybe<RelationType>;
  notIn?: InputMaybe<Array<RelationType>>;
};

export type NestedEnumScoreTypeFilter = {
  equals?: InputMaybe<ScoreType>;
  in?: InputMaybe<Array<ScoreType>>;
  not?: InputMaybe<ScoreType>;
  notIn?: InputMaybe<Array<ScoreType>>;
};

export type NestedEnumScoreTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumScoreTypeFilter>;
  _min?: InputMaybe<NestedEnumScoreTypeFilter>;
  equals?: InputMaybe<ScoreType>;
  in?: InputMaybe<Array<ScoreType>>;
  not?: InputMaybe<ScoreType>;
  notIn?: InputMaybe<Array<ScoreType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedFloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Query = {
  __typename?: 'Query';
  countCategory: Scalars['Int']['output'];
  countCategoryContent: Scalars['Int']['output'];
  countContent: Scalars['Int']['output'];
  countMetaData: Scalars['Int']['output'];
  countScore: Scalars['Int']['output'];
  countSource: Scalars['Int']['output'];
  findFirstCategory?: Maybe<Category>;
  findFirstCategoryContent?: Maybe<CategoryContent>;
  findFirstContent?: Maybe<Content>;
  findFirstMetaData?: Maybe<MetaData>;
  findFirstScore?: Maybe<Score>;
  findFirstSource?: Maybe<Source>;
  findManyCategory: Array<Category>;
  findManyCategoryContent: Array<CategoryContent>;
  findManyContent: Array<Content>;
  findManyMetaData: Array<MetaData>;
  findManyScore: Array<Score>;
  findManySource: Array<Source>;
  findRandomContents: Array<Content>;
  findRandomJobTitles: Array<Category>;
  findUniqueCategory?: Maybe<Category>;
  findUniqueCategoryContent?: Maybe<CategoryContent>;
  findUniqueContent?: Maybe<Content>;
  findUniqueMetaData?: Maybe<MetaData>;
  findUniqueScore?: Maybe<Score>;
  findUniqueSource?: Maybe<Source>;
  searchCategories: Array<Category>;
  searchContents: Array<Content>;
};


export type QueryCountCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryCountCategoryContentArgs = {
  cursor?: InputMaybe<CategoryContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryContentWhereInput>;
};


export type QueryCountContentArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryCountMetaDataArgs = {
  cursor?: InputMaybe<MetaDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<MetaDataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MetaDataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetaDataWhereInput>;
};


export type QueryCountScoreArgs = {
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
};


export type QueryCountSourceArgs = {
  cursor?: InputMaybe<SourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<SourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SourceWhereInput>;
};


export type QueryFindFirstCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryFindFirstCategoryContentArgs = {
  cursor?: InputMaybe<CategoryContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryContentWhereInput>;
};


export type QueryFindFirstContentArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryFindFirstMetaDataArgs = {
  cursor?: InputMaybe<MetaDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<MetaDataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MetaDataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetaDataWhereInput>;
};


export type QueryFindFirstScoreArgs = {
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
};


export type QueryFindFirstSourceArgs = {
  cursor?: InputMaybe<SourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<SourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SourceWhereInput>;
};


export type QueryFindManyCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  randomOrderBy?: InputMaybe<Scalars['Boolean']['input']>;
  seed?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryFindManyCategoryContentArgs = {
  cursor?: InputMaybe<CategoryContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryContentWhereInput>;
};


export type QueryFindManyContentArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  randomOrderBy?: InputMaybe<Scalars['Boolean']['input']>;
  seed?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryFindManyMetaDataArgs = {
  cursor?: InputMaybe<MetaDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<MetaDataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MetaDataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetaDataWhereInput>;
};


export type QueryFindManyScoreArgs = {
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
};


export type QueryFindManySourceArgs = {
  cursor?: InputMaybe<SourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<SourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SourceWhereInput>;
};


export type QueryFindRandomContentsArgs = {
  contentType: ContentTypeOption;
  iSlug?: InputMaybe<Scalars['String']['input']>;
  jSlug?: InputMaybe<Scalars['String']['input']>;
  seed: Scalars['String']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindRandomJobTitlesArgs = {
  iSlug?: InputMaybe<Scalars['String']['input']>;
  seed: Scalars['String']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindUniqueCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type QueryFindUniqueCategoryContentArgs = {
  where: CategoryContentWhereUniqueInput;
};


export type QueryFindUniqueContentArgs = {
  where: ContentWhereUniqueInput;
};


export type QueryFindUniqueMetaDataArgs = {
  where: MetaDataWhereUniqueInput;
};


export type QueryFindUniqueScoreArgs = {
  where: ScoreWhereUniqueInput;
};


export type QueryFindUniqueSourceArgs = {
  where: SourceWhereUniqueInput;
};


export type QuerySearchCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  options?: InputMaybe<SearchCategoriesOptionsInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QuerySearchContentsArgs = {
  contentType: ContentTypeOption;
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  minSearchResults?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
  seed?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RelatedCategories = {
  __typename?: 'RelatedCategories';
  related: Category;
  relatedAt: Scalars['DateTime']['output'];
  relatedBy: Category;
  relatedById: Scalars['String']['output'];
  relatedId: Scalars['String']['output'];
  type: RelationType;
};

export type RelatedCategoriesCountOrderByAggregateInput = {
  relatedAt?: InputMaybe<SortOrder>;
  relatedById?: InputMaybe<SortOrder>;
  relatedId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type RelatedCategoriesCreateInput = {
  related: CategoryCreateNestedOneWithoutRelatedChildrenCategoriesInput;
  relatedAt: Scalars['DateTime']['input'];
  relatedBy: CategoryCreateNestedOneWithoutRelatedCategoriesInput;
  type: RelationType;
};

export type RelatedCategoriesCreateManyInput = {
  relatedAt: Scalars['DateTime']['input'];
  relatedById: Scalars['String']['input'];
  relatedId: Scalars['String']['input'];
  type: RelationType;
};

export type RelatedCategoriesCreateManyRelatedByInput = {
  relatedAt: Scalars['DateTime']['input'];
  relatedId: Scalars['String']['input'];
  type: RelationType;
};

export type RelatedCategoriesCreateManyRelatedByInputEnvelope = {
  data: Array<RelatedCategoriesCreateManyRelatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RelatedCategoriesCreateManyRelatedInput = {
  relatedAt: Scalars['DateTime']['input'];
  relatedById: Scalars['String']['input'];
  type: RelationType;
};

export type RelatedCategoriesCreateManyRelatedInputEnvelope = {
  data: Array<RelatedCategoriesCreateManyRelatedInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RelatedCategoriesCreateNestedManyWithoutRelatedByInput = {
  connect?: InputMaybe<Array<RelatedCategoriesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RelatedCategoriesCreateOrConnectWithoutRelatedByInput>>;
  create?: InputMaybe<Array<RelatedCategoriesCreateWithoutRelatedByInput>>;
  createMany?: InputMaybe<RelatedCategoriesCreateManyRelatedByInputEnvelope>;
};

export type RelatedCategoriesCreateNestedManyWithoutRelatedInput = {
  connect?: InputMaybe<Array<RelatedCategoriesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RelatedCategoriesCreateOrConnectWithoutRelatedInput>>;
  create?: InputMaybe<Array<RelatedCategoriesCreateWithoutRelatedInput>>;
  createMany?: InputMaybe<RelatedCategoriesCreateManyRelatedInputEnvelope>;
};

export type RelatedCategoriesCreateOrConnectWithoutRelatedByInput = {
  create: RelatedCategoriesCreateWithoutRelatedByInput;
  where: RelatedCategoriesWhereUniqueInput;
};

export type RelatedCategoriesCreateOrConnectWithoutRelatedInput = {
  create: RelatedCategoriesCreateWithoutRelatedInput;
  where: RelatedCategoriesWhereUniqueInput;
};

export type RelatedCategoriesCreateWithoutRelatedByInput = {
  related: CategoryCreateNestedOneWithoutRelatedChildrenCategoriesInput;
  relatedAt: Scalars['DateTime']['input'];
  type: RelationType;
};

export type RelatedCategoriesCreateWithoutRelatedInput = {
  relatedAt: Scalars['DateTime']['input'];
  relatedBy: CategoryCreateNestedOneWithoutRelatedCategoriesInput;
  type: RelationType;
};

export type RelatedCategoriesListRelationFilter = {
  every?: InputMaybe<RelatedCategoriesWhereInput>;
  none?: InputMaybe<RelatedCategoriesWhereInput>;
  some?: InputMaybe<RelatedCategoriesWhereInput>;
};

export type RelatedCategoriesMaxOrderByAggregateInput = {
  relatedAt?: InputMaybe<SortOrder>;
  relatedById?: InputMaybe<SortOrder>;
  relatedId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type RelatedCategoriesMinOrderByAggregateInput = {
  relatedAt?: InputMaybe<SortOrder>;
  relatedById?: InputMaybe<SortOrder>;
  relatedId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type RelatedCategoriesOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RelatedCategoriesOrderByWithAggregationInput = {
  _count?: InputMaybe<RelatedCategoriesCountOrderByAggregateInput>;
  _max?: InputMaybe<RelatedCategoriesMaxOrderByAggregateInput>;
  _min?: InputMaybe<RelatedCategoriesMinOrderByAggregateInput>;
  relatedAt?: InputMaybe<SortOrder>;
  relatedById?: InputMaybe<SortOrder>;
  relatedId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type RelatedCategoriesOrderByWithRelationInput = {
  related?: InputMaybe<CategoryOrderByWithRelationInput>;
  relatedAt?: InputMaybe<SortOrder>;
  relatedBy?: InputMaybe<CategoryOrderByWithRelationInput>;
  relatedById?: InputMaybe<SortOrder>;
  relatedId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type RelatedCategoriesRelatedByIdRelatedIdCompoundUniqueInput = {
  relatedById: Scalars['String']['input'];
  relatedId: Scalars['String']['input'];
};

export enum RelatedCategoriesScalarFieldEnum {
  RelatedAt = 'relatedAt',
  RelatedById = 'relatedById',
  RelatedId = 'relatedId',
  Type = 'type'
}

export type RelatedCategoriesScalarWhereInput = {
  AND?: InputMaybe<Array<RelatedCategoriesScalarWhereInput>>;
  NOT?: InputMaybe<Array<RelatedCategoriesScalarWhereInput>>;
  OR?: InputMaybe<Array<RelatedCategoriesScalarWhereInput>>;
  relatedAt?: InputMaybe<DateTimeFilter>;
  relatedById?: InputMaybe<StringFilter>;
  relatedId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumRelationTypeFilter>;
};

export type RelatedCategoriesScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RelatedCategoriesScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<RelatedCategoriesScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RelatedCategoriesScalarWhereWithAggregatesInput>>;
  relatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  relatedById?: InputMaybe<StringWithAggregatesFilter>;
  relatedId?: InputMaybe<StringWithAggregatesFilter>;
  type?: InputMaybe<EnumRelationTypeWithAggregatesFilter>;
};

export type RelatedCategoriesUpdateInput = {
  related?: InputMaybe<CategoryUpdateOneRequiredWithoutRelatedChildrenCategoriesNestedInput>;
  relatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  relatedBy?: InputMaybe<CategoryUpdateOneRequiredWithoutRelatedCategoriesNestedInput>;
  type?: InputMaybe<EnumRelationTypeFieldUpdateOperationsInput>;
};

export type RelatedCategoriesUpdateManyMutationInput = {
  relatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumRelationTypeFieldUpdateOperationsInput>;
};

export type RelatedCategoriesUpdateManyWithWhereWithoutRelatedByInput = {
  data: RelatedCategoriesUpdateManyMutationInput;
  where: RelatedCategoriesScalarWhereInput;
};

export type RelatedCategoriesUpdateManyWithWhereWithoutRelatedInput = {
  data: RelatedCategoriesUpdateManyMutationInput;
  where: RelatedCategoriesScalarWhereInput;
};

export type RelatedCategoriesUpdateManyWithoutRelatedByNestedInput = {
  connect?: InputMaybe<Array<RelatedCategoriesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RelatedCategoriesCreateOrConnectWithoutRelatedByInput>>;
  create?: InputMaybe<Array<RelatedCategoriesCreateWithoutRelatedByInput>>;
  createMany?: InputMaybe<RelatedCategoriesCreateManyRelatedByInputEnvelope>;
  delete?: InputMaybe<Array<RelatedCategoriesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RelatedCategoriesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RelatedCategoriesWhereUniqueInput>>;
  set?: InputMaybe<Array<RelatedCategoriesWhereUniqueInput>>;
  update?: InputMaybe<Array<RelatedCategoriesUpdateWithWhereUniqueWithoutRelatedByInput>>;
  updateMany?: InputMaybe<Array<RelatedCategoriesUpdateManyWithWhereWithoutRelatedByInput>>;
  upsert?: InputMaybe<Array<RelatedCategoriesUpsertWithWhereUniqueWithoutRelatedByInput>>;
};

export type RelatedCategoriesUpdateManyWithoutRelatedNestedInput = {
  connect?: InputMaybe<Array<RelatedCategoriesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RelatedCategoriesCreateOrConnectWithoutRelatedInput>>;
  create?: InputMaybe<Array<RelatedCategoriesCreateWithoutRelatedInput>>;
  createMany?: InputMaybe<RelatedCategoriesCreateManyRelatedInputEnvelope>;
  delete?: InputMaybe<Array<RelatedCategoriesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RelatedCategoriesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RelatedCategoriesWhereUniqueInput>>;
  set?: InputMaybe<Array<RelatedCategoriesWhereUniqueInput>>;
  update?: InputMaybe<Array<RelatedCategoriesUpdateWithWhereUniqueWithoutRelatedInput>>;
  updateMany?: InputMaybe<Array<RelatedCategoriesUpdateManyWithWhereWithoutRelatedInput>>;
  upsert?: InputMaybe<Array<RelatedCategoriesUpsertWithWhereUniqueWithoutRelatedInput>>;
};

export type RelatedCategoriesUpdateWithWhereUniqueWithoutRelatedByInput = {
  data: RelatedCategoriesUpdateWithoutRelatedByInput;
  where: RelatedCategoriesWhereUniqueInput;
};

export type RelatedCategoriesUpdateWithWhereUniqueWithoutRelatedInput = {
  data: RelatedCategoriesUpdateWithoutRelatedInput;
  where: RelatedCategoriesWhereUniqueInput;
};

export type RelatedCategoriesUpdateWithoutRelatedByInput = {
  related?: InputMaybe<CategoryUpdateOneRequiredWithoutRelatedChildrenCategoriesNestedInput>;
  relatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumRelationTypeFieldUpdateOperationsInput>;
};

export type RelatedCategoriesUpdateWithoutRelatedInput = {
  relatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  relatedBy?: InputMaybe<CategoryUpdateOneRequiredWithoutRelatedCategoriesNestedInput>;
  type?: InputMaybe<EnumRelationTypeFieldUpdateOperationsInput>;
};

export type RelatedCategoriesUpsertWithWhereUniqueWithoutRelatedByInput = {
  create: RelatedCategoriesCreateWithoutRelatedByInput;
  update: RelatedCategoriesUpdateWithoutRelatedByInput;
  where: RelatedCategoriesWhereUniqueInput;
};

export type RelatedCategoriesUpsertWithWhereUniqueWithoutRelatedInput = {
  create: RelatedCategoriesCreateWithoutRelatedInput;
  update: RelatedCategoriesUpdateWithoutRelatedInput;
  where: RelatedCategoriesWhereUniqueInput;
};

export type RelatedCategoriesWhereInput = {
  AND?: InputMaybe<Array<RelatedCategoriesWhereInput>>;
  NOT?: InputMaybe<Array<RelatedCategoriesWhereInput>>;
  OR?: InputMaybe<Array<RelatedCategoriesWhereInput>>;
  related?: InputMaybe<CategoryWhereInput>;
  relatedAt?: InputMaybe<DateTimeFilter>;
  relatedBy?: InputMaybe<CategoryWhereInput>;
  relatedById?: InputMaybe<StringFilter>;
  relatedId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumRelationTypeFilter>;
};

export type RelatedCategoriesWhereUniqueInput = {
  AND?: InputMaybe<Array<RelatedCategoriesWhereInput>>;
  NOT?: InputMaybe<Array<RelatedCategoriesWhereInput>>;
  OR?: InputMaybe<Array<RelatedCategoriesWhereInput>>;
  related?: InputMaybe<CategoryWhereInput>;
  relatedAt?: InputMaybe<DateTimeFilter>;
  relatedBy?: InputMaybe<CategoryWhereInput>;
  relatedById?: InputMaybe<StringFilter>;
  relatedById_relatedId?: InputMaybe<RelatedCategoriesRelatedByIdRelatedIdCompoundUniqueInput>;
  relatedId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumRelationTypeFilter>;
};

export enum RelationLoadStrategy {
  Join = 'join',
  Query = 'query'
}

export enum RelationType {
  Fuse = 'FUSE',
  Oa = 'OA'
}

export type Score = {
  __typename?: 'Score';
  categories: Array<Category>;
  contents: Array<Content>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sourcedAt?: Maybe<Scalars['DateTime']['output']>;
  type: ScoreType;
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};


export type ScoreCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type ScoreContentsArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
};

export type ScoreAvgOrderByAggregateInput = {
  value?: InputMaybe<SortOrder>;
};

export type ScoreCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ScoreCreateInput = {
  categories?: InputMaybe<CategoryCreateNestedManyWithoutScoresInput>;
  contents?: InputMaybe<ContentCreateNestedManyWithoutScoresInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  type: ScoreType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  value: Scalars['Float']['input'];
};

export type ScoreCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  type: ScoreType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  value: Scalars['Float']['input'];
};

export type ScoreCreateNestedManyWithoutCategoriesInput = {
  connect?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScoreCreateOrConnectWithoutCategoriesInput>>;
  create?: InputMaybe<Array<ScoreCreateWithoutCategoriesInput>>;
};

export type ScoreCreateNestedManyWithoutContentsInput = {
  connect?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScoreCreateOrConnectWithoutContentsInput>>;
  create?: InputMaybe<Array<ScoreCreateWithoutContentsInput>>;
};

export type ScoreCreateOrConnectWithoutCategoriesInput = {
  create: ScoreCreateWithoutCategoriesInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreCreateOrConnectWithoutContentsInput = {
  create: ScoreCreateWithoutContentsInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreCreateWithoutCategoriesInput = {
  contents?: InputMaybe<ContentCreateNestedManyWithoutScoresInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  type: ScoreType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  value: Scalars['Float']['input'];
};

export type ScoreCreateWithoutContentsInput = {
  categories?: InputMaybe<CategoryCreateNestedManyWithoutScoresInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sourcedAt?: InputMaybe<Scalars['DateTime']['input']>;
  type: ScoreType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  value: Scalars['Float']['input'];
};

export type ScoreListRelationFilter = {
  every?: InputMaybe<ScoreWhereInput>;
  none?: InputMaybe<ScoreWhereInput>;
  some?: InputMaybe<ScoreWhereInput>;
};

export type ScoreMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ScoreMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ScoreOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ScoreOrderByWithAggregationInput = {
  _avg?: InputMaybe<ScoreAvgOrderByAggregateInput>;
  _count?: InputMaybe<ScoreCountOrderByAggregateInput>;
  _max?: InputMaybe<ScoreMaxOrderByAggregateInput>;
  _min?: InputMaybe<ScoreMinOrderByAggregateInput>;
  _sum?: InputMaybe<ScoreSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ScoreOrderByWithRelationInput = {
  categories?: InputMaybe<CategoryOrderByRelationAggregateInput>;
  contents?: InputMaybe<ContentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sourcedAt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum ScoreScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  SourcedAt = 'sourcedAt',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Value = 'value'
}

export type ScoreScalarWhereInput = {
  AND?: InputMaybe<Array<ScoreScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScoreScalarWhereInput>>;
  OR?: InputMaybe<Array<ScoreScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  sourcedAt?: InputMaybe<DateTimeNullableFilter>;
  type?: InputMaybe<EnumScoreTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type ScoreScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ScoreScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ScoreScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ScoreScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  sourcedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  type?: InputMaybe<EnumScoreTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  value?: InputMaybe<FloatWithAggregatesFilter>;
};

export type ScoreSumOrderByAggregateInput = {
  value?: InputMaybe<SortOrder>;
};

export enum ScoreType {
  Experience = 'EXPERIENCE',
  Interest = 'INTEREST',
  Probability = 'PROBABILITY',
  Usage = 'USAGE'
}

export type ScoreUpdateInput = {
  categories?: InputMaybe<CategoryUpdateManyWithoutScoresNestedInput>;
  contents?: InputMaybe<ContentUpdateManyWithoutScoresNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sourcedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumScoreTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ScoreUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sourcedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumScoreTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ScoreUpdateManyWithWhereWithoutCategoriesInput = {
  data: ScoreUpdateManyMutationInput;
  where: ScoreScalarWhereInput;
};

export type ScoreUpdateManyWithWhereWithoutContentsInput = {
  data: ScoreUpdateManyMutationInput;
  where: ScoreScalarWhereInput;
};

export type ScoreUpdateManyWithoutCategoriesNestedInput = {
  connect?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScoreCreateOrConnectWithoutCategoriesInput>>;
  create?: InputMaybe<Array<ScoreCreateWithoutCategoriesInput>>;
  delete?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScoreScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  set?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  update?: InputMaybe<Array<ScoreUpdateWithWhereUniqueWithoutCategoriesInput>>;
  updateMany?: InputMaybe<Array<ScoreUpdateManyWithWhereWithoutCategoriesInput>>;
  upsert?: InputMaybe<Array<ScoreUpsertWithWhereUniqueWithoutCategoriesInput>>;
};

export type ScoreUpdateManyWithoutContentsNestedInput = {
  connect?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScoreCreateOrConnectWithoutContentsInput>>;
  create?: InputMaybe<Array<ScoreCreateWithoutContentsInput>>;
  delete?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScoreScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  set?: InputMaybe<Array<ScoreWhereUniqueInput>>;
  update?: InputMaybe<Array<ScoreUpdateWithWhereUniqueWithoutContentsInput>>;
  updateMany?: InputMaybe<Array<ScoreUpdateManyWithWhereWithoutContentsInput>>;
  upsert?: InputMaybe<Array<ScoreUpsertWithWhereUniqueWithoutContentsInput>>;
};

export type ScoreUpdateWithWhereUniqueWithoutCategoriesInput = {
  data: ScoreUpdateWithoutCategoriesInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreUpdateWithWhereUniqueWithoutContentsInput = {
  data: ScoreUpdateWithoutContentsInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreUpdateWithoutCategoriesInput = {
  contents?: InputMaybe<ContentUpdateManyWithoutScoresNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sourcedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumScoreTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ScoreUpdateWithoutContentsInput = {
  categories?: InputMaybe<CategoryUpdateManyWithoutScoresNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sourcedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumScoreTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ScoreUpsertWithWhereUniqueWithoutCategoriesInput = {
  create: ScoreCreateWithoutCategoriesInput;
  update: ScoreUpdateWithoutCategoriesInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreUpsertWithWhereUniqueWithoutContentsInput = {
  create: ScoreCreateWithoutContentsInput;
  update: ScoreUpdateWithoutContentsInput;
  where: ScoreWhereUniqueInput;
};

export type ScoreWhereInput = {
  AND?: InputMaybe<Array<ScoreWhereInput>>;
  NOT?: InputMaybe<Array<ScoreWhereInput>>;
  OR?: InputMaybe<Array<ScoreWhereInput>>;
  categories?: InputMaybe<CategoryListRelationFilter>;
  contents?: InputMaybe<ContentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  sourcedAt?: InputMaybe<DateTimeNullableFilter>;
  type?: InputMaybe<EnumScoreTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type ScoreWhereUniqueInput = {
  AND?: InputMaybe<Array<ScoreWhereInput>>;
  NOT?: InputMaybe<Array<ScoreWhereInput>>;
  OR?: InputMaybe<Array<ScoreWhereInput>>;
  categories?: InputMaybe<CategoryListRelationFilter>;
  contents?: InputMaybe<ContentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  sourcedAt?: InputMaybe<DateTimeNullableFilter>;
  type?: InputMaybe<EnumScoreTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type SearchCategoriesOptionsInput = {
  algo?: InputMaybe<AlgorithmTypeOption>;
  field?: InputMaybe<Scalars['String']['input']>;
  searchSlug?: InputMaybe<Scalars['Boolean']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  threshold?: InputMaybe<Scalars['Int']['input']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Source = {
  __typename?: 'Source';
  categories: Array<CategorySource>;
  contents: Array<ContentSource>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type SourceCategoriesArgs = {
  cursor?: InputMaybe<CategorySourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategorySourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategorySourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategorySourceWhereInput>;
};


export type SourceContentsArgs = {
  cursor?: InputMaybe<ContentSourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentSourceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentSourceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentSourceWhereInput>;
};

export type SourceCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SourceCreateInput = {
  categories?: InputMaybe<CategorySourceCreateNestedManyWithoutSourceInput>;
  contents?: InputMaybe<ContentSourceCreateNestedManyWithoutSourceInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SourceCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SourceCreateNestedOneWithoutCategoriesInput = {
  connect?: InputMaybe<SourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SourceCreateOrConnectWithoutCategoriesInput>;
  create?: InputMaybe<SourceCreateWithoutCategoriesInput>;
};

export type SourceCreateNestedOneWithoutContentsInput = {
  connect?: InputMaybe<SourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SourceCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<SourceCreateWithoutContentsInput>;
};

export type SourceCreateOrConnectWithoutCategoriesInput = {
  create: SourceCreateWithoutCategoriesInput;
  where: SourceWhereUniqueInput;
};

export type SourceCreateOrConnectWithoutContentsInput = {
  create: SourceCreateWithoutContentsInput;
  where: SourceWhereUniqueInput;
};

export type SourceCreateWithoutCategoriesInput = {
  contents?: InputMaybe<ContentSourceCreateNestedManyWithoutSourceInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SourceCreateWithoutContentsInput = {
  categories?: InputMaybe<CategorySourceCreateNestedManyWithoutSourceInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SourceMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SourceMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SourceNullableRelationFilter = {
  is?: InputMaybe<SourceWhereInput>;
  isNot?: InputMaybe<SourceWhereInput>;
};

export type SourceOrderByWithAggregationInput = {
  _count?: InputMaybe<SourceCountOrderByAggregateInput>;
  _max?: InputMaybe<SourceMaxOrderByAggregateInput>;
  _min?: InputMaybe<SourceMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SourceOrderByWithRelationInput = {
  categories?: InputMaybe<CategorySourceOrderByRelationAggregateInput>;
  contents?: InputMaybe<ContentSourceOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum SourceScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Slug = 'slug',
  Text = 'text',
  UpdatedAt = 'updatedAt'
}

export type SourceScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SourceScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SourceScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SourceScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  text?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type SourceUpdateInput = {
  categories?: InputMaybe<CategorySourceUpdateManyWithoutSourceNestedInput>;
  contents?: InputMaybe<ContentSourceUpdateManyWithoutSourceNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SourceUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SourceUpdateOneWithoutCategoriesNestedInput = {
  connect?: InputMaybe<SourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SourceCreateOrConnectWithoutCategoriesInput>;
  create?: InputMaybe<SourceCreateWithoutCategoriesInput>;
  delete?: InputMaybe<SourceWhereInput>;
  disconnect?: InputMaybe<SourceWhereInput>;
  update?: InputMaybe<SourceUpdateToOneWithWhereWithoutCategoriesInput>;
  upsert?: InputMaybe<SourceUpsertWithoutCategoriesInput>;
};

export type SourceUpdateOneWithoutContentsNestedInput = {
  connect?: InputMaybe<SourceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SourceCreateOrConnectWithoutContentsInput>;
  create?: InputMaybe<SourceCreateWithoutContentsInput>;
  delete?: InputMaybe<SourceWhereInput>;
  disconnect?: InputMaybe<SourceWhereInput>;
  update?: InputMaybe<SourceUpdateToOneWithWhereWithoutContentsInput>;
  upsert?: InputMaybe<SourceUpsertWithoutContentsInput>;
};

export type SourceUpdateToOneWithWhereWithoutCategoriesInput = {
  data: SourceUpdateWithoutCategoriesInput;
  where?: InputMaybe<SourceWhereInput>;
};

export type SourceUpdateToOneWithWhereWithoutContentsInput = {
  data: SourceUpdateWithoutContentsInput;
  where?: InputMaybe<SourceWhereInput>;
};

export type SourceUpdateWithoutCategoriesInput = {
  contents?: InputMaybe<ContentSourceUpdateManyWithoutSourceNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SourceUpdateWithoutContentsInput = {
  categories?: InputMaybe<CategorySourceUpdateManyWithoutSourceNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SourceUpsertWithoutCategoriesInput = {
  create: SourceCreateWithoutCategoriesInput;
  update: SourceUpdateWithoutCategoriesInput;
  where?: InputMaybe<SourceWhereInput>;
};

export type SourceUpsertWithoutContentsInput = {
  create: SourceCreateWithoutContentsInput;
  update: SourceUpdateWithoutContentsInput;
  where?: InputMaybe<SourceWhereInput>;
};

export type SourceWhereInput = {
  AND?: InputMaybe<Array<SourceWhereInput>>;
  NOT?: InputMaybe<Array<SourceWhereInput>>;
  OR?: InputMaybe<Array<SourceWhereInput>>;
  categories?: InputMaybe<CategorySourceListRelationFilter>;
  contents?: InputMaybe<ContentSourceListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SourceWhereUniqueInput = {
  AND?: InputMaybe<Array<SourceWhereInput>>;
  NOT?: InputMaybe<Array<SourceWhereInput>>;
  OR?: InputMaybe<Array<SourceWhereInput>>;
  categories?: InputMaybe<CategorySourceListRelationFilter>;
  contents?: InputMaybe<ContentSourceListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export enum TransactionIsolationLevel {
  ReadCommitted = 'ReadCommitted',
  ReadUncommitted = 'ReadUncommitted',
  RepeatableRead = 'RepeatableRead',
  Serializable = 'Serializable'
}

export type CountCategoryQueryVariables = Exact<{
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum> | CategoryScalarFieldEnum>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput> | CategoryOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
}>;


export type CountCategoryQuery = { __typename?: 'Query', countCategory: number };

export type CountCategoryContentQueryVariables = Exact<{
  cursor?: InputMaybe<CategoryContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryContentScalarFieldEnum> | CategoryContentScalarFieldEnum>;
  orderBy?: InputMaybe<Array<CategoryContentOrderByWithRelationInput> | CategoryContentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryContentWhereInput>;
}>;


export type CountCategoryContentQuery = { __typename?: 'Query', countCategoryContent: number };

export type CountContentQueryVariables = Exact<{
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum> | ContentScalarFieldEnum>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput> | ContentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
}>;


export type CountContentQuery = { __typename?: 'Query', countContent: number };

export type CountMetaDataQueryVariables = Exact<{
  cursor?: InputMaybe<MetaDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<MetaDataScalarFieldEnum> | MetaDataScalarFieldEnum>;
  orderBy?: InputMaybe<Array<MetaDataOrderByWithRelationInput> | MetaDataOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetaDataWhereInput>;
}>;


export type CountMetaDataQuery = { __typename?: 'Query', countMetaData: number };

export type CountScoreQueryVariables = Exact<{
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum> | ScoreScalarFieldEnum>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput> | ScoreOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
}>;


export type CountScoreQuery = { __typename?: 'Query', countScore: number };

export type CountSourceQueryVariables = Exact<{
  cursor?: InputMaybe<SourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<SourceScalarFieldEnum> | SourceScalarFieldEnum>;
  orderBy?: InputMaybe<Array<SourceOrderByWithRelationInput> | SourceOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SourceWhereInput>;
}>;


export type CountSourceQuery = { __typename?: 'Query', countSource: number };

export type FindFirstCategoryQueryVariables = Exact<{
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum> | CategoryScalarFieldEnum>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput> | CategoryOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
}>;


export type FindFirstCategoryQuery = { __typename?: 'Query', findFirstCategory?: { __typename?: 'Category', createdAt: any, id: string, name: string, slug: string, status: ContentStatus, type: CategoryType, updatedAt: any } | null };

export type FindFirstCategoryContentQueryVariables = Exact<{
  cursor?: InputMaybe<CategoryContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryContentScalarFieldEnum> | CategoryContentScalarFieldEnum>;
  orderBy?: InputMaybe<Array<CategoryContentOrderByWithRelationInput> | CategoryContentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryContentWhereInput>;
}>;


export type FindFirstCategoryContentQuery = { __typename?: 'Query', findFirstCategoryContent?: { __typename?: 'CategoryContent', categoryId: string, contentId: string, createdAt: any, updatedAt: any } | null };

export type FindFirstContentQueryVariables = Exact<{
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum> | ContentScalarFieldEnum>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput> | ContentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
}>;


export type FindFirstContentQuery = { __typename?: 'Query', findFirstContent?: { __typename?: 'Content', createdAt: any, id: string, slug: string, status: ContentStatus, text: string, type: ContentType, updatedAt: any } | null };

export type FindFirstMetaDataQueryVariables = Exact<{
  cursor?: InputMaybe<MetaDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<MetaDataScalarFieldEnum> | MetaDataScalarFieldEnum>;
  orderBy?: InputMaybe<Array<MetaDataOrderByWithRelationInput> | MetaDataOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetaDataWhereInput>;
}>;


export type FindFirstMetaDataQuery = { __typename?: 'Query', findFirstMetaData?: { __typename?: 'MetaData', canonicalId?: string | null, categoryId?: string | null, createdAt: any, id: string, redirectId?: string | null, updatedAt: any } | null };

export type FindFirstScoreQueryVariables = Exact<{
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum> | ScoreScalarFieldEnum>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput> | ScoreOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
}>;


export type FindFirstScoreQuery = { __typename?: 'Query', findFirstScore?: { __typename?: 'Score', createdAt: any, id: string, sourcedAt?: any | null, type: ScoreType, updatedAt: any, value: number } | null };

export type FindFirstSourceQueryVariables = Exact<{
  cursor?: InputMaybe<SourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<SourceScalarFieldEnum> | SourceScalarFieldEnum>;
  orderBy?: InputMaybe<Array<SourceOrderByWithRelationInput> | SourceOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SourceWhereInput>;
}>;


export type FindFirstSourceQuery = { __typename?: 'Query', findFirstSource?: { __typename?: 'Source', createdAt: any, id: string, slug: string, text: string, updatedAt: any } | null };

export type FindManyCategoryContentQueryVariables = Exact<{
  cursor?: InputMaybe<CategoryContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryContentScalarFieldEnum> | CategoryContentScalarFieldEnum>;
  orderBy?: InputMaybe<Array<CategoryContentOrderByWithRelationInput> | CategoryContentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryContentWhereInput>;
}>;


export type FindManyCategoryContentQuery = { __typename?: 'Query', findManyCategoryContent: Array<{ __typename?: 'CategoryContent', categoryId: string, contentId: string, createdAt: any, updatedAt: any }> };

export type FindManyMetaDataQueryVariables = Exact<{
  cursor?: InputMaybe<MetaDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<MetaDataScalarFieldEnum> | MetaDataScalarFieldEnum>;
  orderBy?: InputMaybe<Array<MetaDataOrderByWithRelationInput> | MetaDataOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetaDataWhereInput>;
}>;


export type FindManyMetaDataQuery = { __typename?: 'Query', findManyMetaData: Array<{ __typename?: 'MetaData', canonicalId?: string | null, categoryId?: string | null, createdAt: any, id: string, redirectId?: string | null, updatedAt: any }> };

export type FindManyScoreQueryVariables = Exact<{
  cursor?: InputMaybe<ScoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScoreScalarFieldEnum> | ScoreScalarFieldEnum>;
  orderBy?: InputMaybe<Array<ScoreOrderByWithRelationInput> | ScoreOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreWhereInput>;
}>;


export type FindManyScoreQuery = { __typename?: 'Query', findManyScore: Array<{ __typename?: 'Score', createdAt: any, id: string, sourcedAt?: any | null, type: ScoreType, updatedAt: any, value: number }> };

export type FindManySourceQueryVariables = Exact<{
  cursor?: InputMaybe<SourceWhereUniqueInput>;
  distinct?: InputMaybe<Array<SourceScalarFieldEnum> | SourceScalarFieldEnum>;
  orderBy?: InputMaybe<Array<SourceOrderByWithRelationInput> | SourceOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SourceWhereInput>;
}>;


export type FindManySourceQuery = { __typename?: 'Query', findManySource: Array<{ __typename?: 'Source', createdAt: any, id: string, slug: string, text: string, updatedAt: any }> };

export type FindUniqueCategoryQueryVariables = Exact<{
  where: CategoryWhereUniqueInput;
}>;


export type FindUniqueCategoryQuery = { __typename?: 'Query', findUniqueCategory?: { __typename?: 'Category', createdAt: any, id: string, name: string, slug: string, status: ContentStatus, type: CategoryType, updatedAt: any } | null };

export type FindUniqueCategoryContentQueryVariables = Exact<{
  where: CategoryContentWhereUniqueInput;
}>;


export type FindUniqueCategoryContentQuery = { __typename?: 'Query', findUniqueCategoryContent?: { __typename?: 'CategoryContent', categoryId: string, contentId: string, createdAt: any, updatedAt: any } | null };

export type FindUniqueContentQueryVariables = Exact<{
  where: ContentWhereUniqueInput;
}>;


export type FindUniqueContentQuery = { __typename?: 'Query', findUniqueContent?: { __typename?: 'Content', createdAt: any, id: string, slug: string, status: ContentStatus, text: string, type: ContentType, updatedAt: any } | null };

export type FindUniqueMetaDataQueryVariables = Exact<{
  where: MetaDataWhereUniqueInput;
}>;


export type FindUniqueMetaDataQuery = { __typename?: 'Query', findUniqueMetaData?: { __typename?: 'MetaData', canonicalId?: string | null, categoryId?: string | null, createdAt: any, id: string, redirectId?: string | null, updatedAt: any } | null };

export type FindUniqueScoreQueryVariables = Exact<{
  where: ScoreWhereUniqueInput;
}>;


export type FindUniqueScoreQuery = { __typename?: 'Query', findUniqueScore?: { __typename?: 'Score', createdAt: any, id: string, sourcedAt?: any | null, type: ScoreType, updatedAt: any, value: number } | null };

export type FindUniqueSourceQueryVariables = Exact<{
  where: SourceWhereUniqueInput;
}>;


export type FindUniqueSourceQuery = { __typename?: 'Query', findUniqueSource?: { __typename?: 'Source', createdAt: any, id: string, slug: string, text: string, updatedAt: any } | null };

export type AllJobTitlesWithCanonicalQueryVariables = Exact<{
  where?: InputMaybe<CategoryWhereInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  industryOrderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput> | CategoryOrderByWithRelationInput>;
  version?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AllJobTitlesWithCanonicalQuery = { __typename?: 'Query', findManyCategory: Array<{ __typename?: 'Category', id: string, slug: string, parentCategories: Array<{ __typename?: 'Category', id: string, slug: string }>, metaData?: { __typename?: 'MetaData', canonical?: { __typename?: 'MetaData', category?: { __typename?: 'Category', id: string, slug: string, parentCategories: Array<{ __typename?: 'Category', id: string, slug: string }> } | null } | null } | null }> };

export type FindManyCategoryQueryVariables = Exact<{
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum> | CategoryScalarFieldEnum>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput> | CategoryOrderByWithRelationInput>;
  randomOrderBy?: InputMaybe<Scalars['Boolean']['input']>;
  seed?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
}>;


export type FindManyCategoryQuery = { __typename?: 'Query', findManyCategory: Array<{ __typename?: 'Category', id: string, name: string, slug: string, type: CategoryType, industries: Array<{ __typename?: 'Category', id: string, slug: string, name: string }> }> };

export type FindManyContentQueryVariables = Exact<{
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum> | ContentScalarFieldEnum>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput> | ContentOrderByWithRelationInput>;
  randomOrderBy?: InputMaybe<Scalars['Boolean']['input']>;
  seed?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
}>;


export type FindManyContentQuery = { __typename?: 'Query', findManyContent: Array<{ __typename?: 'Content', id: string, text: string }> };

export type FindRandomCategoriesQueryVariables = Exact<{
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum> | CategoryScalarFieldEnum>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput> | CategoryOrderByWithRelationInput>;
  randomOrderBy?: InputMaybe<Scalars['Boolean']['input']>;
  seed?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
}>;


export type FindRandomCategoriesQuery = { __typename?: 'Query', findManyCategory: Array<{ __typename?: 'Category', id: string, name: string, slug: string, status: ContentStatus, type: CategoryType, createdAt: any, updatedAt: any }> };

export type FindRandomContentsQueryVariables = Exact<{
  contentType: ContentTypeOption;
  iSlug?: InputMaybe<Scalars['String']['input']>;
  jSlug?: InputMaybe<Scalars['String']['input']>;
  seed: Scalars['String']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
}>;


export type FindRandomContentsQuery = { __typename?: 'Query', findRandomContents: Array<{ __typename?: 'Content', id: string, text: string }> };

export type FindRandomJobTitlesQueryVariables = Exact<{
  iSlug?: InputMaybe<Scalars['String']['input']>;
  seed: Scalars['String']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
}>;


export type FindRandomJobTitlesQuery = { __typename?: 'Query', findRandomJobTitles: Array<{ __typename?: 'Category', id: string, name: string, slug: string, type: CategoryType, industries: Array<{ __typename?: 'Category', id: string, slug: string, name: string }> }> };

export type JobTitleWithCanonicalQueryVariables = Exact<{
  where?: InputMaybe<CategoryWhereInput>;
  industryOrderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput> | CategoryOrderByWithRelationInput>;
}>;


export type JobTitleWithCanonicalQuery = { __typename?: 'Query', findFirstCategory?: { __typename?: 'Category', id: string, status: ContentStatus, slug: string, name: string, parentCategories: Array<{ __typename?: 'Category', id: string, name: string, slug: string }>, metaData?: { __typename?: 'MetaData', canonical?: { __typename?: 'MetaData', category?: { __typename?: 'Category', id: string, status: ContentStatus, slug: string, parentCategories: Array<{ __typename?: 'Category', id: string, name: string, slug: string }> } | null } | null } | null } | null };

export type SearchCategoriesQueryVariables = Exact<{
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum> | CategoryScalarFieldEnum>;
  options?: InputMaybe<SearchCategoriesOptionsInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput> | CategoryOrderByWithRelationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
}>;


export type SearchCategoriesQuery = { __typename?: 'Query', searchCategories: Array<{ __typename?: 'Category', id: string, slug: string, name: string, industries: Array<{ __typename?: 'Category', id: string, slug: string, name: string }> }> };

export type SearchContentsQueryVariables = Exact<{
  contentType: ContentTypeOption;
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum> | ContentScalarFieldEnum>;
  minSearchResults?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput> | ContentOrderByWithRelationInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  seed?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
}>;


export type SearchContentsQuery = { __typename?: 'Query', searchContents: Array<{ __typename?: 'Content', id: string, text: string }> };


export const CountCategoryDocument = gql`
    query countCategory($cursor: CategoryWhereUniqueInput, $distinct: [CategoryScalarFieldEnum!], $orderBy: [CategoryOrderByWithRelationInput!], $skip: Int, $take: Int, $where: CategoryWhereInput) {
  countCategory(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  )
}
    `;

/**
 * __useCountCategoryQuery__
 *
 * To run a query within a React component, call `useCountCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCategoryQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountCategoryQuery(baseOptions?: Apollo.QueryHookOptions<CountCategoryQuery, CountCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountCategoryQuery, CountCategoryQueryVariables>(CountCategoryDocument, options);
      }
export function useCountCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountCategoryQuery, CountCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountCategoryQuery, CountCategoryQueryVariables>(CountCategoryDocument, options);
        }
export function useCountCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CountCategoryQuery, CountCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountCategoryQuery, CountCategoryQueryVariables>(CountCategoryDocument, options);
        }
export type CountCategoryQueryHookResult = ReturnType<typeof useCountCategoryQuery>;
export type CountCategoryLazyQueryHookResult = ReturnType<typeof useCountCategoryLazyQuery>;
export type CountCategorySuspenseQueryHookResult = ReturnType<typeof useCountCategorySuspenseQuery>;
export type CountCategoryQueryResult = Apollo.QueryResult<CountCategoryQuery, CountCategoryQueryVariables>;
export const CountCategoryContentDocument = gql`
    query countCategoryContent($cursor: CategoryContentWhereUniqueInput, $distinct: [CategoryContentScalarFieldEnum!], $orderBy: [CategoryContentOrderByWithRelationInput!], $skip: Int, $take: Int, $where: CategoryContentWhereInput) {
  countCategoryContent(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  )
}
    `;

/**
 * __useCountCategoryContentQuery__
 *
 * To run a query within a React component, call `useCountCategoryContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCategoryContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCategoryContentQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountCategoryContentQuery(baseOptions?: Apollo.QueryHookOptions<CountCategoryContentQuery, CountCategoryContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountCategoryContentQuery, CountCategoryContentQueryVariables>(CountCategoryContentDocument, options);
      }
export function useCountCategoryContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountCategoryContentQuery, CountCategoryContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountCategoryContentQuery, CountCategoryContentQueryVariables>(CountCategoryContentDocument, options);
        }
export function useCountCategoryContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CountCategoryContentQuery, CountCategoryContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountCategoryContentQuery, CountCategoryContentQueryVariables>(CountCategoryContentDocument, options);
        }
export type CountCategoryContentQueryHookResult = ReturnType<typeof useCountCategoryContentQuery>;
export type CountCategoryContentLazyQueryHookResult = ReturnType<typeof useCountCategoryContentLazyQuery>;
export type CountCategoryContentSuspenseQueryHookResult = ReturnType<typeof useCountCategoryContentSuspenseQuery>;
export type CountCategoryContentQueryResult = Apollo.QueryResult<CountCategoryContentQuery, CountCategoryContentQueryVariables>;
export const CountContentDocument = gql`
    query countContent($cursor: ContentWhereUniqueInput, $distinct: [ContentScalarFieldEnum!], $orderBy: [ContentOrderByWithRelationInput!], $skip: Int, $take: Int, $where: ContentWhereInput) {
  countContent(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  )
}
    `;

/**
 * __useCountContentQuery__
 *
 * To run a query within a React component, call `useCountContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountContentQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountContentQuery(baseOptions?: Apollo.QueryHookOptions<CountContentQuery, CountContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountContentQuery, CountContentQueryVariables>(CountContentDocument, options);
      }
export function useCountContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountContentQuery, CountContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountContentQuery, CountContentQueryVariables>(CountContentDocument, options);
        }
export function useCountContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CountContentQuery, CountContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountContentQuery, CountContentQueryVariables>(CountContentDocument, options);
        }
export type CountContentQueryHookResult = ReturnType<typeof useCountContentQuery>;
export type CountContentLazyQueryHookResult = ReturnType<typeof useCountContentLazyQuery>;
export type CountContentSuspenseQueryHookResult = ReturnType<typeof useCountContentSuspenseQuery>;
export type CountContentQueryResult = Apollo.QueryResult<CountContentQuery, CountContentQueryVariables>;
export const CountMetaDataDocument = gql`
    query countMetaData($cursor: MetaDataWhereUniqueInput, $distinct: [MetaDataScalarFieldEnum!], $orderBy: [MetaDataOrderByWithRelationInput!], $skip: Int, $take: Int, $where: MetaDataWhereInput) {
  countMetaData(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  )
}
    `;

/**
 * __useCountMetaDataQuery__
 *
 * To run a query within a React component, call `useCountMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountMetaDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountMetaDataQuery(baseOptions?: Apollo.QueryHookOptions<CountMetaDataQuery, CountMetaDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountMetaDataQuery, CountMetaDataQueryVariables>(CountMetaDataDocument, options);
      }
export function useCountMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountMetaDataQuery, CountMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountMetaDataQuery, CountMetaDataQueryVariables>(CountMetaDataDocument, options);
        }
export function useCountMetaDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CountMetaDataQuery, CountMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountMetaDataQuery, CountMetaDataQueryVariables>(CountMetaDataDocument, options);
        }
export type CountMetaDataQueryHookResult = ReturnType<typeof useCountMetaDataQuery>;
export type CountMetaDataLazyQueryHookResult = ReturnType<typeof useCountMetaDataLazyQuery>;
export type CountMetaDataSuspenseQueryHookResult = ReturnType<typeof useCountMetaDataSuspenseQuery>;
export type CountMetaDataQueryResult = Apollo.QueryResult<CountMetaDataQuery, CountMetaDataQueryVariables>;
export const CountScoreDocument = gql`
    query countScore($cursor: ScoreWhereUniqueInput, $distinct: [ScoreScalarFieldEnum!], $orderBy: [ScoreOrderByWithRelationInput!], $skip: Int, $take: Int, $where: ScoreWhereInput) {
  countScore(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  )
}
    `;

/**
 * __useCountScoreQuery__
 *
 * To run a query within a React component, call `useCountScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountScoreQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountScoreQuery(baseOptions?: Apollo.QueryHookOptions<CountScoreQuery, CountScoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountScoreQuery, CountScoreQueryVariables>(CountScoreDocument, options);
      }
export function useCountScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountScoreQuery, CountScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountScoreQuery, CountScoreQueryVariables>(CountScoreDocument, options);
        }
export function useCountScoreSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CountScoreQuery, CountScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountScoreQuery, CountScoreQueryVariables>(CountScoreDocument, options);
        }
export type CountScoreQueryHookResult = ReturnType<typeof useCountScoreQuery>;
export type CountScoreLazyQueryHookResult = ReturnType<typeof useCountScoreLazyQuery>;
export type CountScoreSuspenseQueryHookResult = ReturnType<typeof useCountScoreSuspenseQuery>;
export type CountScoreQueryResult = Apollo.QueryResult<CountScoreQuery, CountScoreQueryVariables>;
export const CountSourceDocument = gql`
    query countSource($cursor: SourceWhereUniqueInput, $distinct: [SourceScalarFieldEnum!], $orderBy: [SourceOrderByWithRelationInput!], $skip: Int, $take: Int, $where: SourceWhereInput) {
  countSource(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  )
}
    `;

/**
 * __useCountSourceQuery__
 *
 * To run a query within a React component, call `useCountSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountSourceQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountSourceQuery(baseOptions?: Apollo.QueryHookOptions<CountSourceQuery, CountSourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountSourceQuery, CountSourceQueryVariables>(CountSourceDocument, options);
      }
export function useCountSourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountSourceQuery, CountSourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountSourceQuery, CountSourceQueryVariables>(CountSourceDocument, options);
        }
export function useCountSourceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CountSourceQuery, CountSourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountSourceQuery, CountSourceQueryVariables>(CountSourceDocument, options);
        }
export type CountSourceQueryHookResult = ReturnType<typeof useCountSourceQuery>;
export type CountSourceLazyQueryHookResult = ReturnType<typeof useCountSourceLazyQuery>;
export type CountSourceSuspenseQueryHookResult = ReturnType<typeof useCountSourceSuspenseQuery>;
export type CountSourceQueryResult = Apollo.QueryResult<CountSourceQuery, CountSourceQueryVariables>;
export const FindFirstCategoryDocument = gql`
    query findFirstCategory($cursor: CategoryWhereUniqueInput, $distinct: [CategoryScalarFieldEnum!], $orderBy: [CategoryOrderByWithRelationInput!], $skip: Int, $take: Int, $where: CategoryWhereInput) {
  findFirstCategory(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  ) {
    createdAt
    id
    name
    slug
    status
    type
    updatedAt
  }
}
    `;

/**
 * __useFindFirstCategoryQuery__
 *
 * To run a query within a React component, call `useFindFirstCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstCategoryQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstCategoryQuery(baseOptions?: Apollo.QueryHookOptions<FindFirstCategoryQuery, FindFirstCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstCategoryQuery, FindFirstCategoryQueryVariables>(FindFirstCategoryDocument, options);
      }
export function useFindFirstCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstCategoryQuery, FindFirstCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstCategoryQuery, FindFirstCategoryQueryVariables>(FindFirstCategoryDocument, options);
        }
export function useFindFirstCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindFirstCategoryQuery, FindFirstCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindFirstCategoryQuery, FindFirstCategoryQueryVariables>(FindFirstCategoryDocument, options);
        }
export type FindFirstCategoryQueryHookResult = ReturnType<typeof useFindFirstCategoryQuery>;
export type FindFirstCategoryLazyQueryHookResult = ReturnType<typeof useFindFirstCategoryLazyQuery>;
export type FindFirstCategorySuspenseQueryHookResult = ReturnType<typeof useFindFirstCategorySuspenseQuery>;
export type FindFirstCategoryQueryResult = Apollo.QueryResult<FindFirstCategoryQuery, FindFirstCategoryQueryVariables>;
export const FindFirstCategoryContentDocument = gql`
    query findFirstCategoryContent($cursor: CategoryContentWhereUniqueInput, $distinct: [CategoryContentScalarFieldEnum!], $orderBy: [CategoryContentOrderByWithRelationInput!], $skip: Int, $take: Int, $where: CategoryContentWhereInput) {
  findFirstCategoryContent(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  ) {
    categoryId
    contentId
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFindFirstCategoryContentQuery__
 *
 * To run a query within a React component, call `useFindFirstCategoryContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstCategoryContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstCategoryContentQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstCategoryContentQuery(baseOptions?: Apollo.QueryHookOptions<FindFirstCategoryContentQuery, FindFirstCategoryContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstCategoryContentQuery, FindFirstCategoryContentQueryVariables>(FindFirstCategoryContentDocument, options);
      }
export function useFindFirstCategoryContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstCategoryContentQuery, FindFirstCategoryContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstCategoryContentQuery, FindFirstCategoryContentQueryVariables>(FindFirstCategoryContentDocument, options);
        }
export function useFindFirstCategoryContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindFirstCategoryContentQuery, FindFirstCategoryContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindFirstCategoryContentQuery, FindFirstCategoryContentQueryVariables>(FindFirstCategoryContentDocument, options);
        }
export type FindFirstCategoryContentQueryHookResult = ReturnType<typeof useFindFirstCategoryContentQuery>;
export type FindFirstCategoryContentLazyQueryHookResult = ReturnType<typeof useFindFirstCategoryContentLazyQuery>;
export type FindFirstCategoryContentSuspenseQueryHookResult = ReturnType<typeof useFindFirstCategoryContentSuspenseQuery>;
export type FindFirstCategoryContentQueryResult = Apollo.QueryResult<FindFirstCategoryContentQuery, FindFirstCategoryContentQueryVariables>;
export const FindFirstContentDocument = gql`
    query findFirstContent($cursor: ContentWhereUniqueInput, $distinct: [ContentScalarFieldEnum!], $orderBy: [ContentOrderByWithRelationInput!], $skip: Int, $take: Int, $where: ContentWhereInput) {
  findFirstContent(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  ) {
    createdAt
    id
    slug
    status
    text
    type
    updatedAt
  }
}
    `;

/**
 * __useFindFirstContentQuery__
 *
 * To run a query within a React component, call `useFindFirstContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstContentQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstContentQuery(baseOptions?: Apollo.QueryHookOptions<FindFirstContentQuery, FindFirstContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstContentQuery, FindFirstContentQueryVariables>(FindFirstContentDocument, options);
      }
export function useFindFirstContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstContentQuery, FindFirstContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstContentQuery, FindFirstContentQueryVariables>(FindFirstContentDocument, options);
        }
export function useFindFirstContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindFirstContentQuery, FindFirstContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindFirstContentQuery, FindFirstContentQueryVariables>(FindFirstContentDocument, options);
        }
export type FindFirstContentQueryHookResult = ReturnType<typeof useFindFirstContentQuery>;
export type FindFirstContentLazyQueryHookResult = ReturnType<typeof useFindFirstContentLazyQuery>;
export type FindFirstContentSuspenseQueryHookResult = ReturnType<typeof useFindFirstContentSuspenseQuery>;
export type FindFirstContentQueryResult = Apollo.QueryResult<FindFirstContentQuery, FindFirstContentQueryVariables>;
export const FindFirstMetaDataDocument = gql`
    query findFirstMetaData($cursor: MetaDataWhereUniqueInput, $distinct: [MetaDataScalarFieldEnum!], $orderBy: [MetaDataOrderByWithRelationInput!], $skip: Int, $take: Int, $where: MetaDataWhereInput) {
  findFirstMetaData(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  ) {
    canonicalId
    categoryId
    createdAt
    id
    redirectId
    updatedAt
  }
}
    `;

/**
 * __useFindFirstMetaDataQuery__
 *
 * To run a query within a React component, call `useFindFirstMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstMetaDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstMetaDataQuery(baseOptions?: Apollo.QueryHookOptions<FindFirstMetaDataQuery, FindFirstMetaDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstMetaDataQuery, FindFirstMetaDataQueryVariables>(FindFirstMetaDataDocument, options);
      }
export function useFindFirstMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstMetaDataQuery, FindFirstMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstMetaDataQuery, FindFirstMetaDataQueryVariables>(FindFirstMetaDataDocument, options);
        }
export function useFindFirstMetaDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindFirstMetaDataQuery, FindFirstMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindFirstMetaDataQuery, FindFirstMetaDataQueryVariables>(FindFirstMetaDataDocument, options);
        }
export type FindFirstMetaDataQueryHookResult = ReturnType<typeof useFindFirstMetaDataQuery>;
export type FindFirstMetaDataLazyQueryHookResult = ReturnType<typeof useFindFirstMetaDataLazyQuery>;
export type FindFirstMetaDataSuspenseQueryHookResult = ReturnType<typeof useFindFirstMetaDataSuspenseQuery>;
export type FindFirstMetaDataQueryResult = Apollo.QueryResult<FindFirstMetaDataQuery, FindFirstMetaDataQueryVariables>;
export const FindFirstScoreDocument = gql`
    query findFirstScore($cursor: ScoreWhereUniqueInput, $distinct: [ScoreScalarFieldEnum!], $orderBy: [ScoreOrderByWithRelationInput!], $skip: Int, $take: Int, $where: ScoreWhereInput) {
  findFirstScore(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  ) {
    createdAt
    id
    sourcedAt
    type
    updatedAt
    value
  }
}
    `;

/**
 * __useFindFirstScoreQuery__
 *
 * To run a query within a React component, call `useFindFirstScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstScoreQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstScoreQuery(baseOptions?: Apollo.QueryHookOptions<FindFirstScoreQuery, FindFirstScoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstScoreQuery, FindFirstScoreQueryVariables>(FindFirstScoreDocument, options);
      }
export function useFindFirstScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstScoreQuery, FindFirstScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstScoreQuery, FindFirstScoreQueryVariables>(FindFirstScoreDocument, options);
        }
export function useFindFirstScoreSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindFirstScoreQuery, FindFirstScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindFirstScoreQuery, FindFirstScoreQueryVariables>(FindFirstScoreDocument, options);
        }
export type FindFirstScoreQueryHookResult = ReturnType<typeof useFindFirstScoreQuery>;
export type FindFirstScoreLazyQueryHookResult = ReturnType<typeof useFindFirstScoreLazyQuery>;
export type FindFirstScoreSuspenseQueryHookResult = ReturnType<typeof useFindFirstScoreSuspenseQuery>;
export type FindFirstScoreQueryResult = Apollo.QueryResult<FindFirstScoreQuery, FindFirstScoreQueryVariables>;
export const FindFirstSourceDocument = gql`
    query findFirstSource($cursor: SourceWhereUniqueInput, $distinct: [SourceScalarFieldEnum!], $orderBy: [SourceOrderByWithRelationInput!], $skip: Int, $take: Int, $where: SourceWhereInput) {
  findFirstSource(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  ) {
    createdAt
    id
    slug
    text
    updatedAt
  }
}
    `;

/**
 * __useFindFirstSourceQuery__
 *
 * To run a query within a React component, call `useFindFirstSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstSourceQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstSourceQuery(baseOptions?: Apollo.QueryHookOptions<FindFirstSourceQuery, FindFirstSourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstSourceQuery, FindFirstSourceQueryVariables>(FindFirstSourceDocument, options);
      }
export function useFindFirstSourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstSourceQuery, FindFirstSourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstSourceQuery, FindFirstSourceQueryVariables>(FindFirstSourceDocument, options);
        }
export function useFindFirstSourceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindFirstSourceQuery, FindFirstSourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindFirstSourceQuery, FindFirstSourceQueryVariables>(FindFirstSourceDocument, options);
        }
export type FindFirstSourceQueryHookResult = ReturnType<typeof useFindFirstSourceQuery>;
export type FindFirstSourceLazyQueryHookResult = ReturnType<typeof useFindFirstSourceLazyQuery>;
export type FindFirstSourceSuspenseQueryHookResult = ReturnType<typeof useFindFirstSourceSuspenseQuery>;
export type FindFirstSourceQueryResult = Apollo.QueryResult<FindFirstSourceQuery, FindFirstSourceQueryVariables>;
export const FindManyCategoryContentDocument = gql`
    query findManyCategoryContent($cursor: CategoryContentWhereUniqueInput, $distinct: [CategoryContentScalarFieldEnum!], $orderBy: [CategoryContentOrderByWithRelationInput!], $skip: Int, $take: Int, $where: CategoryContentWhereInput) {
  findManyCategoryContent(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  ) {
    categoryId
    contentId
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFindManyCategoryContentQuery__
 *
 * To run a query within a React component, call `useFindManyCategoryContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyCategoryContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyCategoryContentQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyCategoryContentQuery(baseOptions?: Apollo.QueryHookOptions<FindManyCategoryContentQuery, FindManyCategoryContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyCategoryContentQuery, FindManyCategoryContentQueryVariables>(FindManyCategoryContentDocument, options);
      }
export function useFindManyCategoryContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyCategoryContentQuery, FindManyCategoryContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyCategoryContentQuery, FindManyCategoryContentQueryVariables>(FindManyCategoryContentDocument, options);
        }
export function useFindManyCategoryContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindManyCategoryContentQuery, FindManyCategoryContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindManyCategoryContentQuery, FindManyCategoryContentQueryVariables>(FindManyCategoryContentDocument, options);
        }
export type FindManyCategoryContentQueryHookResult = ReturnType<typeof useFindManyCategoryContentQuery>;
export type FindManyCategoryContentLazyQueryHookResult = ReturnType<typeof useFindManyCategoryContentLazyQuery>;
export type FindManyCategoryContentSuspenseQueryHookResult = ReturnType<typeof useFindManyCategoryContentSuspenseQuery>;
export type FindManyCategoryContentQueryResult = Apollo.QueryResult<FindManyCategoryContentQuery, FindManyCategoryContentQueryVariables>;
export const FindManyMetaDataDocument = gql`
    query findManyMetaData($cursor: MetaDataWhereUniqueInput, $distinct: [MetaDataScalarFieldEnum!], $orderBy: [MetaDataOrderByWithRelationInput!], $skip: Int, $take: Int, $where: MetaDataWhereInput) {
  findManyMetaData(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  ) {
    canonicalId
    categoryId
    createdAt
    id
    redirectId
    updatedAt
  }
}
    `;

/**
 * __useFindManyMetaDataQuery__
 *
 * To run a query within a React component, call `useFindManyMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyMetaDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyMetaDataQuery(baseOptions?: Apollo.QueryHookOptions<FindManyMetaDataQuery, FindManyMetaDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyMetaDataQuery, FindManyMetaDataQueryVariables>(FindManyMetaDataDocument, options);
      }
export function useFindManyMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyMetaDataQuery, FindManyMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyMetaDataQuery, FindManyMetaDataQueryVariables>(FindManyMetaDataDocument, options);
        }
export function useFindManyMetaDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindManyMetaDataQuery, FindManyMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindManyMetaDataQuery, FindManyMetaDataQueryVariables>(FindManyMetaDataDocument, options);
        }
export type FindManyMetaDataQueryHookResult = ReturnType<typeof useFindManyMetaDataQuery>;
export type FindManyMetaDataLazyQueryHookResult = ReturnType<typeof useFindManyMetaDataLazyQuery>;
export type FindManyMetaDataSuspenseQueryHookResult = ReturnType<typeof useFindManyMetaDataSuspenseQuery>;
export type FindManyMetaDataQueryResult = Apollo.QueryResult<FindManyMetaDataQuery, FindManyMetaDataQueryVariables>;
export const FindManyScoreDocument = gql`
    query findManyScore($cursor: ScoreWhereUniqueInput, $distinct: [ScoreScalarFieldEnum!], $orderBy: [ScoreOrderByWithRelationInput!], $skip: Int, $take: Int, $where: ScoreWhereInput) {
  findManyScore(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  ) {
    createdAt
    id
    sourcedAt
    type
    updatedAt
    value
  }
}
    `;

/**
 * __useFindManyScoreQuery__
 *
 * To run a query within a React component, call `useFindManyScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyScoreQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyScoreQuery(baseOptions?: Apollo.QueryHookOptions<FindManyScoreQuery, FindManyScoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyScoreQuery, FindManyScoreQueryVariables>(FindManyScoreDocument, options);
      }
export function useFindManyScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyScoreQuery, FindManyScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyScoreQuery, FindManyScoreQueryVariables>(FindManyScoreDocument, options);
        }
export function useFindManyScoreSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindManyScoreQuery, FindManyScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindManyScoreQuery, FindManyScoreQueryVariables>(FindManyScoreDocument, options);
        }
export type FindManyScoreQueryHookResult = ReturnType<typeof useFindManyScoreQuery>;
export type FindManyScoreLazyQueryHookResult = ReturnType<typeof useFindManyScoreLazyQuery>;
export type FindManyScoreSuspenseQueryHookResult = ReturnType<typeof useFindManyScoreSuspenseQuery>;
export type FindManyScoreQueryResult = Apollo.QueryResult<FindManyScoreQuery, FindManyScoreQueryVariables>;
export const FindManySourceDocument = gql`
    query findManySource($cursor: SourceWhereUniqueInput, $distinct: [SourceScalarFieldEnum!], $orderBy: [SourceOrderByWithRelationInput!], $skip: Int, $take: Int, $where: SourceWhereInput) {
  findManySource(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
    where: $where
  ) {
    createdAt
    id
    slug
    text
    updatedAt
  }
}
    `;

/**
 * __useFindManySourceQuery__
 *
 * To run a query within a React component, call `useFindManySourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManySourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManySourceQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManySourceQuery(baseOptions?: Apollo.QueryHookOptions<FindManySourceQuery, FindManySourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManySourceQuery, FindManySourceQueryVariables>(FindManySourceDocument, options);
      }
export function useFindManySourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManySourceQuery, FindManySourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManySourceQuery, FindManySourceQueryVariables>(FindManySourceDocument, options);
        }
export function useFindManySourceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindManySourceQuery, FindManySourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindManySourceQuery, FindManySourceQueryVariables>(FindManySourceDocument, options);
        }
export type FindManySourceQueryHookResult = ReturnType<typeof useFindManySourceQuery>;
export type FindManySourceLazyQueryHookResult = ReturnType<typeof useFindManySourceLazyQuery>;
export type FindManySourceSuspenseQueryHookResult = ReturnType<typeof useFindManySourceSuspenseQuery>;
export type FindManySourceQueryResult = Apollo.QueryResult<FindManySourceQuery, FindManySourceQueryVariables>;
export const FindUniqueCategoryDocument = gql`
    query findUniqueCategory($where: CategoryWhereUniqueInput!) {
  findUniqueCategory(where: $where) {
    createdAt
    id
    name
    slug
    status
    type
    updatedAt
  }
}
    `;

/**
 * __useFindUniqueCategoryQuery__
 *
 * To run a query within a React component, call `useFindUniqueCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueCategoryQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueCategoryQuery, FindUniqueCategoryQueryVariables> & ({ variables: FindUniqueCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueCategoryQuery, FindUniqueCategoryQueryVariables>(FindUniqueCategoryDocument, options);
      }
export function useFindUniqueCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueCategoryQuery, FindUniqueCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueCategoryQuery, FindUniqueCategoryQueryVariables>(FindUniqueCategoryDocument, options);
        }
export function useFindUniqueCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindUniqueCategoryQuery, FindUniqueCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindUniqueCategoryQuery, FindUniqueCategoryQueryVariables>(FindUniqueCategoryDocument, options);
        }
export type FindUniqueCategoryQueryHookResult = ReturnType<typeof useFindUniqueCategoryQuery>;
export type FindUniqueCategoryLazyQueryHookResult = ReturnType<typeof useFindUniqueCategoryLazyQuery>;
export type FindUniqueCategorySuspenseQueryHookResult = ReturnType<typeof useFindUniqueCategorySuspenseQuery>;
export type FindUniqueCategoryQueryResult = Apollo.QueryResult<FindUniqueCategoryQuery, FindUniqueCategoryQueryVariables>;
export const FindUniqueCategoryContentDocument = gql`
    query findUniqueCategoryContent($where: CategoryContentWhereUniqueInput!) {
  findUniqueCategoryContent(where: $where) {
    categoryId
    contentId
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFindUniqueCategoryContentQuery__
 *
 * To run a query within a React component, call `useFindUniqueCategoryContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueCategoryContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueCategoryContentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueCategoryContentQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueCategoryContentQuery, FindUniqueCategoryContentQueryVariables> & ({ variables: FindUniqueCategoryContentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueCategoryContentQuery, FindUniqueCategoryContentQueryVariables>(FindUniqueCategoryContentDocument, options);
      }
export function useFindUniqueCategoryContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueCategoryContentQuery, FindUniqueCategoryContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueCategoryContentQuery, FindUniqueCategoryContentQueryVariables>(FindUniqueCategoryContentDocument, options);
        }
export function useFindUniqueCategoryContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindUniqueCategoryContentQuery, FindUniqueCategoryContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindUniqueCategoryContentQuery, FindUniqueCategoryContentQueryVariables>(FindUniqueCategoryContentDocument, options);
        }
export type FindUniqueCategoryContentQueryHookResult = ReturnType<typeof useFindUniqueCategoryContentQuery>;
export type FindUniqueCategoryContentLazyQueryHookResult = ReturnType<typeof useFindUniqueCategoryContentLazyQuery>;
export type FindUniqueCategoryContentSuspenseQueryHookResult = ReturnType<typeof useFindUniqueCategoryContentSuspenseQuery>;
export type FindUniqueCategoryContentQueryResult = Apollo.QueryResult<FindUniqueCategoryContentQuery, FindUniqueCategoryContentQueryVariables>;
export const FindUniqueContentDocument = gql`
    query findUniqueContent($where: ContentWhereUniqueInput!) {
  findUniqueContent(where: $where) {
    createdAt
    id
    slug
    status
    text
    type
    updatedAt
  }
}
    `;

/**
 * __useFindUniqueContentQuery__
 *
 * To run a query within a React component, call `useFindUniqueContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueContentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueContentQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueContentQuery, FindUniqueContentQueryVariables> & ({ variables: FindUniqueContentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueContentQuery, FindUniqueContentQueryVariables>(FindUniqueContentDocument, options);
      }
export function useFindUniqueContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueContentQuery, FindUniqueContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueContentQuery, FindUniqueContentQueryVariables>(FindUniqueContentDocument, options);
        }
export function useFindUniqueContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindUniqueContentQuery, FindUniqueContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindUniqueContentQuery, FindUniqueContentQueryVariables>(FindUniqueContentDocument, options);
        }
export type FindUniqueContentQueryHookResult = ReturnType<typeof useFindUniqueContentQuery>;
export type FindUniqueContentLazyQueryHookResult = ReturnType<typeof useFindUniqueContentLazyQuery>;
export type FindUniqueContentSuspenseQueryHookResult = ReturnType<typeof useFindUniqueContentSuspenseQuery>;
export type FindUniqueContentQueryResult = Apollo.QueryResult<FindUniqueContentQuery, FindUniqueContentQueryVariables>;
export const FindUniqueMetaDataDocument = gql`
    query findUniqueMetaData($where: MetaDataWhereUniqueInput!) {
  findUniqueMetaData(where: $where) {
    canonicalId
    categoryId
    createdAt
    id
    redirectId
    updatedAt
  }
}
    `;

/**
 * __useFindUniqueMetaDataQuery__
 *
 * To run a query within a React component, call `useFindUniqueMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueMetaDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueMetaDataQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueMetaDataQuery, FindUniqueMetaDataQueryVariables> & ({ variables: FindUniqueMetaDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueMetaDataQuery, FindUniqueMetaDataQueryVariables>(FindUniqueMetaDataDocument, options);
      }
export function useFindUniqueMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueMetaDataQuery, FindUniqueMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueMetaDataQuery, FindUniqueMetaDataQueryVariables>(FindUniqueMetaDataDocument, options);
        }
export function useFindUniqueMetaDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindUniqueMetaDataQuery, FindUniqueMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindUniqueMetaDataQuery, FindUniqueMetaDataQueryVariables>(FindUniqueMetaDataDocument, options);
        }
export type FindUniqueMetaDataQueryHookResult = ReturnType<typeof useFindUniqueMetaDataQuery>;
export type FindUniqueMetaDataLazyQueryHookResult = ReturnType<typeof useFindUniqueMetaDataLazyQuery>;
export type FindUniqueMetaDataSuspenseQueryHookResult = ReturnType<typeof useFindUniqueMetaDataSuspenseQuery>;
export type FindUniqueMetaDataQueryResult = Apollo.QueryResult<FindUniqueMetaDataQuery, FindUniqueMetaDataQueryVariables>;
export const FindUniqueScoreDocument = gql`
    query findUniqueScore($where: ScoreWhereUniqueInput!) {
  findUniqueScore(where: $where) {
    createdAt
    id
    sourcedAt
    type
    updatedAt
    value
  }
}
    `;

/**
 * __useFindUniqueScoreQuery__
 *
 * To run a query within a React component, call `useFindUniqueScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueScoreQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueScoreQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueScoreQuery, FindUniqueScoreQueryVariables> & ({ variables: FindUniqueScoreQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueScoreQuery, FindUniqueScoreQueryVariables>(FindUniqueScoreDocument, options);
      }
export function useFindUniqueScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueScoreQuery, FindUniqueScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueScoreQuery, FindUniqueScoreQueryVariables>(FindUniqueScoreDocument, options);
        }
export function useFindUniqueScoreSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindUniqueScoreQuery, FindUniqueScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindUniqueScoreQuery, FindUniqueScoreQueryVariables>(FindUniqueScoreDocument, options);
        }
export type FindUniqueScoreQueryHookResult = ReturnType<typeof useFindUniqueScoreQuery>;
export type FindUniqueScoreLazyQueryHookResult = ReturnType<typeof useFindUniqueScoreLazyQuery>;
export type FindUniqueScoreSuspenseQueryHookResult = ReturnType<typeof useFindUniqueScoreSuspenseQuery>;
export type FindUniqueScoreQueryResult = Apollo.QueryResult<FindUniqueScoreQuery, FindUniqueScoreQueryVariables>;
export const FindUniqueSourceDocument = gql`
    query findUniqueSource($where: SourceWhereUniqueInput!) {
  findUniqueSource(where: $where) {
    createdAt
    id
    slug
    text
    updatedAt
  }
}
    `;

/**
 * __useFindUniqueSourceQuery__
 *
 * To run a query within a React component, call `useFindUniqueSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueSourceQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueSourceQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueSourceQuery, FindUniqueSourceQueryVariables> & ({ variables: FindUniqueSourceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueSourceQuery, FindUniqueSourceQueryVariables>(FindUniqueSourceDocument, options);
      }
export function useFindUniqueSourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueSourceQuery, FindUniqueSourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueSourceQuery, FindUniqueSourceQueryVariables>(FindUniqueSourceDocument, options);
        }
export function useFindUniqueSourceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindUniqueSourceQuery, FindUniqueSourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindUniqueSourceQuery, FindUniqueSourceQueryVariables>(FindUniqueSourceDocument, options);
        }
export type FindUniqueSourceQueryHookResult = ReturnType<typeof useFindUniqueSourceQuery>;
export type FindUniqueSourceLazyQueryHookResult = ReturnType<typeof useFindUniqueSourceLazyQuery>;
export type FindUniqueSourceSuspenseQueryHookResult = ReturnType<typeof useFindUniqueSourceSuspenseQuery>;
export type FindUniqueSourceQueryResult = Apollo.QueryResult<FindUniqueSourceQuery, FindUniqueSourceQueryVariables>;
export const AllJobTitlesWithCanonicalDocument = gql`
    query allJobTitlesWithCanonical($where: CategoryWhereInput, $take: Int, $skip: Int, $industryOrderBy: [CategoryOrderByWithRelationInput!], $version: Int) {
  findManyCategory(where: $where, take: $take, skip: $skip, version: $version) {
    id
    slug
    parentCategories(where: {type: {equals: INDUSTRY}}, orderBy: $industryOrderBy) {
      id
      slug
    }
    metaData {
      canonical {
        category {
          id
          slug
          parentCategories(where: {type: {equals: INDUSTRY}}, orderBy: $industryOrderBy) {
            id
            slug
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAllJobTitlesWithCanonicalQuery__
 *
 * To run a query within a React component, call `useAllJobTitlesWithCanonicalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllJobTitlesWithCanonicalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllJobTitlesWithCanonicalQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      industryOrderBy: // value for 'industryOrderBy'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useAllJobTitlesWithCanonicalQuery(baseOptions?: Apollo.QueryHookOptions<AllJobTitlesWithCanonicalQuery, AllJobTitlesWithCanonicalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllJobTitlesWithCanonicalQuery, AllJobTitlesWithCanonicalQueryVariables>(AllJobTitlesWithCanonicalDocument, options);
      }
export function useAllJobTitlesWithCanonicalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllJobTitlesWithCanonicalQuery, AllJobTitlesWithCanonicalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllJobTitlesWithCanonicalQuery, AllJobTitlesWithCanonicalQueryVariables>(AllJobTitlesWithCanonicalDocument, options);
        }
export function useAllJobTitlesWithCanonicalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllJobTitlesWithCanonicalQuery, AllJobTitlesWithCanonicalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllJobTitlesWithCanonicalQuery, AllJobTitlesWithCanonicalQueryVariables>(AllJobTitlesWithCanonicalDocument, options);
        }
export type AllJobTitlesWithCanonicalQueryHookResult = ReturnType<typeof useAllJobTitlesWithCanonicalQuery>;
export type AllJobTitlesWithCanonicalLazyQueryHookResult = ReturnType<typeof useAllJobTitlesWithCanonicalLazyQuery>;
export type AllJobTitlesWithCanonicalSuspenseQueryHookResult = ReturnType<typeof useAllJobTitlesWithCanonicalSuspenseQuery>;
export type AllJobTitlesWithCanonicalQueryResult = Apollo.QueryResult<AllJobTitlesWithCanonicalQuery, AllJobTitlesWithCanonicalQueryVariables>;
export const FindManyCategoryDocument = gql`
    query findManyCategory($cursor: CategoryWhereUniqueInput, $distinct: [CategoryScalarFieldEnum!], $orderBy: [CategoryOrderByWithRelationInput!], $randomOrderBy: Boolean, $seed: String, $skip: Int, $take: Int, $version: Int, $where: CategoryWhereInput) {
  findManyCategory(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    randomOrderBy: $randomOrderBy
    seed: $seed
    skip: $skip
    take: $take
    version: $version
    where: $where
  ) {
    id
    name
    slug
    type
    industries: parentCategories(
      where: {type: {equals: INDUSTRY}, status: {equals: PUBLISHED}}
      orderBy: {slug: asc}
    ) {
      id
      slug
      name
    }
  }
}
    `;

/**
 * __useFindManyCategoryQuery__
 *
 * To run a query within a React component, call `useFindManyCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyCategoryQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      randomOrderBy: // value for 'randomOrderBy'
 *      seed: // value for 'seed'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      version: // value for 'version'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyCategoryQuery(baseOptions?: Apollo.QueryHookOptions<FindManyCategoryQuery, FindManyCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyCategoryQuery, FindManyCategoryQueryVariables>(FindManyCategoryDocument, options);
      }
export function useFindManyCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyCategoryQuery, FindManyCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyCategoryQuery, FindManyCategoryQueryVariables>(FindManyCategoryDocument, options);
        }
export function useFindManyCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindManyCategoryQuery, FindManyCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindManyCategoryQuery, FindManyCategoryQueryVariables>(FindManyCategoryDocument, options);
        }
export type FindManyCategoryQueryHookResult = ReturnType<typeof useFindManyCategoryQuery>;
export type FindManyCategoryLazyQueryHookResult = ReturnType<typeof useFindManyCategoryLazyQuery>;
export type FindManyCategorySuspenseQueryHookResult = ReturnType<typeof useFindManyCategorySuspenseQuery>;
export type FindManyCategoryQueryResult = Apollo.QueryResult<FindManyCategoryQuery, FindManyCategoryQueryVariables>;
export const FindManyContentDocument = gql`
    query findManyContent($cursor: ContentWhereUniqueInput, $distinct: [ContentScalarFieldEnum!], $orderBy: [ContentOrderByWithRelationInput!], $randomOrderBy: Boolean, $seed: String, $skip: Int, $take: Int, $version: Int, $where: ContentWhereInput) {
  findManyContent(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    randomOrderBy: $randomOrderBy
    seed: $seed
    skip: $skip
    take: $take
    version: $version
    where: $where
  ) {
    id
    text
  }
}
    `;

/**
 * __useFindManyContentQuery__
 *
 * To run a query within a React component, call `useFindManyContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyContentQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      randomOrderBy: // value for 'randomOrderBy'
 *      seed: // value for 'seed'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      version: // value for 'version'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyContentQuery(baseOptions?: Apollo.QueryHookOptions<FindManyContentQuery, FindManyContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyContentQuery, FindManyContentQueryVariables>(FindManyContentDocument, options);
      }
export function useFindManyContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyContentQuery, FindManyContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyContentQuery, FindManyContentQueryVariables>(FindManyContentDocument, options);
        }
export function useFindManyContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindManyContentQuery, FindManyContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindManyContentQuery, FindManyContentQueryVariables>(FindManyContentDocument, options);
        }
export type FindManyContentQueryHookResult = ReturnType<typeof useFindManyContentQuery>;
export type FindManyContentLazyQueryHookResult = ReturnType<typeof useFindManyContentLazyQuery>;
export type FindManyContentSuspenseQueryHookResult = ReturnType<typeof useFindManyContentSuspenseQuery>;
export type FindManyContentQueryResult = Apollo.QueryResult<FindManyContentQuery, FindManyContentQueryVariables>;
export const FindRandomCategoriesDocument = gql`
    query findRandomCategories($cursor: CategoryWhereUniqueInput, $distinct: [CategoryScalarFieldEnum!], $orderBy: [CategoryOrderByWithRelationInput!], $randomOrderBy: Boolean, $seed: String, $skip: Int, $take: Int, $version: Int, $where: CategoryWhereInput) {
  findManyCategory(
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    randomOrderBy: $randomOrderBy
    seed: $seed
    skip: $skip
    take: $take
    version: $version
    where: $where
  ) {
    id
    name
    slug
    status
    type
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFindRandomCategoriesQuery__
 *
 * To run a query within a React component, call `useFindRandomCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRandomCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRandomCategoriesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      orderBy: // value for 'orderBy'
 *      randomOrderBy: // value for 'randomOrderBy'
 *      seed: // value for 'seed'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      version: // value for 'version'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindRandomCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<FindRandomCategoriesQuery, FindRandomCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindRandomCategoriesQuery, FindRandomCategoriesQueryVariables>(FindRandomCategoriesDocument, options);
      }
export function useFindRandomCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindRandomCategoriesQuery, FindRandomCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindRandomCategoriesQuery, FindRandomCategoriesQueryVariables>(FindRandomCategoriesDocument, options);
        }
export function useFindRandomCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindRandomCategoriesQuery, FindRandomCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindRandomCategoriesQuery, FindRandomCategoriesQueryVariables>(FindRandomCategoriesDocument, options);
        }
export type FindRandomCategoriesQueryHookResult = ReturnType<typeof useFindRandomCategoriesQuery>;
export type FindRandomCategoriesLazyQueryHookResult = ReturnType<typeof useFindRandomCategoriesLazyQuery>;
export type FindRandomCategoriesSuspenseQueryHookResult = ReturnType<typeof useFindRandomCategoriesSuspenseQuery>;
export type FindRandomCategoriesQueryResult = Apollo.QueryResult<FindRandomCategoriesQuery, FindRandomCategoriesQueryVariables>;
export const FindRandomContentsDocument = gql`
    query findRandomContents($contentType: ContentTypeOption!, $iSlug: String, $jSlug: String, $seed: String!, $take: Int, $version: Int) {
  findRandomContents(
    contentType: $contentType
    iSlug: $iSlug
    jSlug: $jSlug
    seed: $seed
    take: $take
    version: $version
  ) {
    id
    text
  }
}
    `;

/**
 * __useFindRandomContentsQuery__
 *
 * To run a query within a React component, call `useFindRandomContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRandomContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRandomContentsQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      iSlug: // value for 'iSlug'
 *      jSlug: // value for 'jSlug'
 *      seed: // value for 'seed'
 *      take: // value for 'take'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useFindRandomContentsQuery(baseOptions: Apollo.QueryHookOptions<FindRandomContentsQuery, FindRandomContentsQueryVariables> & ({ variables: FindRandomContentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindRandomContentsQuery, FindRandomContentsQueryVariables>(FindRandomContentsDocument, options);
      }
export function useFindRandomContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindRandomContentsQuery, FindRandomContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindRandomContentsQuery, FindRandomContentsQueryVariables>(FindRandomContentsDocument, options);
        }
export function useFindRandomContentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindRandomContentsQuery, FindRandomContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindRandomContentsQuery, FindRandomContentsQueryVariables>(FindRandomContentsDocument, options);
        }
export type FindRandomContentsQueryHookResult = ReturnType<typeof useFindRandomContentsQuery>;
export type FindRandomContentsLazyQueryHookResult = ReturnType<typeof useFindRandomContentsLazyQuery>;
export type FindRandomContentsSuspenseQueryHookResult = ReturnType<typeof useFindRandomContentsSuspenseQuery>;
export type FindRandomContentsQueryResult = Apollo.QueryResult<FindRandomContentsQuery, FindRandomContentsQueryVariables>;
export const FindRandomJobTitlesDocument = gql`
    query findRandomJobTitles($iSlug: String, $seed: String!, $take: Int, $version: Int) {
  findRandomJobTitles(iSlug: $iSlug, seed: $seed, take: $take, version: $version) {
    id
    name
    slug
    type
    industries: parentCategories(
      where: {type: {equals: INDUSTRY}, status: {equals: PUBLISHED}}
      orderBy: {slug: asc}
    ) {
      id
      slug
      name
    }
  }
}
    `;

/**
 * __useFindRandomJobTitlesQuery__
 *
 * To run a query within a React component, call `useFindRandomJobTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRandomJobTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRandomJobTitlesQuery({
 *   variables: {
 *      iSlug: // value for 'iSlug'
 *      seed: // value for 'seed'
 *      take: // value for 'take'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useFindRandomJobTitlesQuery(baseOptions: Apollo.QueryHookOptions<FindRandomJobTitlesQuery, FindRandomJobTitlesQueryVariables> & ({ variables: FindRandomJobTitlesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindRandomJobTitlesQuery, FindRandomJobTitlesQueryVariables>(FindRandomJobTitlesDocument, options);
      }
export function useFindRandomJobTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindRandomJobTitlesQuery, FindRandomJobTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindRandomJobTitlesQuery, FindRandomJobTitlesQueryVariables>(FindRandomJobTitlesDocument, options);
        }
export function useFindRandomJobTitlesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindRandomJobTitlesQuery, FindRandomJobTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindRandomJobTitlesQuery, FindRandomJobTitlesQueryVariables>(FindRandomJobTitlesDocument, options);
        }
export type FindRandomJobTitlesQueryHookResult = ReturnType<typeof useFindRandomJobTitlesQuery>;
export type FindRandomJobTitlesLazyQueryHookResult = ReturnType<typeof useFindRandomJobTitlesLazyQuery>;
export type FindRandomJobTitlesSuspenseQueryHookResult = ReturnType<typeof useFindRandomJobTitlesSuspenseQuery>;
export type FindRandomJobTitlesQueryResult = Apollo.QueryResult<FindRandomJobTitlesQuery, FindRandomJobTitlesQueryVariables>;
export const JobTitleWithCanonicalDocument = gql`
    query jobTitleWithCanonical($where: CategoryWhereInput, $industryOrderBy: [CategoryOrderByWithRelationInput!]) {
  findFirstCategory(where: $where) {
    id
    status
    slug
    name
    parentCategories(where: {type: {equals: INDUSTRY}}, orderBy: $industryOrderBy) {
      id
      name
      slug
    }
    metaData {
      canonical {
        category {
          id
          status
          slug
          parentCategories(where: {type: {equals: INDUSTRY}}, orderBy: $industryOrderBy) {
            id
            name
            slug
          }
        }
      }
    }
  }
}
    `;

/**
 * __useJobTitleWithCanonicalQuery__
 *
 * To run a query within a React component, call `useJobTitleWithCanonicalQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobTitleWithCanonicalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobTitleWithCanonicalQuery({
 *   variables: {
 *      where: // value for 'where'
 *      industryOrderBy: // value for 'industryOrderBy'
 *   },
 * });
 */
export function useJobTitleWithCanonicalQuery(baseOptions?: Apollo.QueryHookOptions<JobTitleWithCanonicalQuery, JobTitleWithCanonicalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobTitleWithCanonicalQuery, JobTitleWithCanonicalQueryVariables>(JobTitleWithCanonicalDocument, options);
      }
export function useJobTitleWithCanonicalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobTitleWithCanonicalQuery, JobTitleWithCanonicalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobTitleWithCanonicalQuery, JobTitleWithCanonicalQueryVariables>(JobTitleWithCanonicalDocument, options);
        }
export function useJobTitleWithCanonicalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<JobTitleWithCanonicalQuery, JobTitleWithCanonicalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<JobTitleWithCanonicalQuery, JobTitleWithCanonicalQueryVariables>(JobTitleWithCanonicalDocument, options);
        }
export type JobTitleWithCanonicalQueryHookResult = ReturnType<typeof useJobTitleWithCanonicalQuery>;
export type JobTitleWithCanonicalLazyQueryHookResult = ReturnType<typeof useJobTitleWithCanonicalLazyQuery>;
export type JobTitleWithCanonicalSuspenseQueryHookResult = ReturnType<typeof useJobTitleWithCanonicalSuspenseQuery>;
export type JobTitleWithCanonicalQueryResult = Apollo.QueryResult<JobTitleWithCanonicalQuery, JobTitleWithCanonicalQueryVariables>;
export const SearchCategoriesDocument = gql`
    query searchCategories($cursor: CategoryWhereUniqueInput, $distinct: [CategoryScalarFieldEnum!], $options: SearchCategoriesOptionsInput, $orderBy: [CategoryOrderByWithRelationInput!], $search: String, $skip: Int, $take: Int, $version: Int, $where: CategoryWhereInput) {
  searchCategories(
    cursor: $cursor
    distinct: $distinct
    options: $options
    orderBy: $orderBy
    search: $search
    skip: $skip
    take: $take
    version: $version
    where: $where
  ) {
    id
    slug
    name
    industries: parentCategories(
      where: {type: {equals: INDUSTRY}, status: {equals: PUBLISHED}}
      orderBy: {slug: asc}
    ) {
      id
      slug
      name
    }
  }
}
    `;

/**
 * __useSearchCategoriesQuery__
 *
 * To run a query within a React component, call `useSearchCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCategoriesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      options: // value for 'options'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      version: // value for 'version'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSearchCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<SearchCategoriesQuery, SearchCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCategoriesQuery, SearchCategoriesQueryVariables>(SearchCategoriesDocument, options);
      }
export function useSearchCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCategoriesQuery, SearchCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCategoriesQuery, SearchCategoriesQueryVariables>(SearchCategoriesDocument, options);
        }
export function useSearchCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchCategoriesQuery, SearchCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCategoriesQuery, SearchCategoriesQueryVariables>(SearchCategoriesDocument, options);
        }
export type SearchCategoriesQueryHookResult = ReturnType<typeof useSearchCategoriesQuery>;
export type SearchCategoriesLazyQueryHookResult = ReturnType<typeof useSearchCategoriesLazyQuery>;
export type SearchCategoriesSuspenseQueryHookResult = ReturnType<typeof useSearchCategoriesSuspenseQuery>;
export type SearchCategoriesQueryResult = Apollo.QueryResult<SearchCategoriesQuery, SearchCategoriesQueryVariables>;
export const SearchContentsDocument = gql`
    query searchContents($contentType: ContentTypeOption!, $cursor: ContentWhereUniqueInput, $distinct: [ContentScalarFieldEnum!], $minSearchResults: Int, $orderBy: [ContentOrderByWithRelationInput!], $search: String, $seed: String, $skip: Int, $take: Int, $version: Int, $where: ContentWhereInput) {
  searchContents(
    contentType: $contentType
    cursor: $cursor
    distinct: $distinct
    minSearchResults: $minSearchResults
    orderBy: $orderBy
    search: $search
    seed: $seed
    skip: $skip
    take: $take
    version: $version
    where: $where
  ) {
    id
    text
  }
}
    `;

/**
 * __useSearchContentsQuery__
 *
 * To run a query within a React component, call `useSearchContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContentsQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      minSearchResults: // value for 'minSearchResults'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      seed: // value for 'seed'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      version: // value for 'version'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSearchContentsQuery(baseOptions: Apollo.QueryHookOptions<SearchContentsQuery, SearchContentsQueryVariables> & ({ variables: SearchContentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchContentsQuery, SearchContentsQueryVariables>(SearchContentsDocument, options);
      }
export function useSearchContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchContentsQuery, SearchContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchContentsQuery, SearchContentsQueryVariables>(SearchContentsDocument, options);
        }
export function useSearchContentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchContentsQuery, SearchContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchContentsQuery, SearchContentsQueryVariables>(SearchContentsDocument, options);
        }
export type SearchContentsQueryHookResult = ReturnType<typeof useSearchContentsQuery>;
export type SearchContentsLazyQueryHookResult = ReturnType<typeof useSearchContentsLazyQuery>;
export type SearchContentsSuspenseQueryHookResult = ReturnType<typeof useSearchContentsSuspenseQuery>;
export type SearchContentsQueryResult = Apollo.QueryResult<SearchContentsQuery, SearchContentsQueryVariables>;