import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import { NOINDEX } from '@glass/common/modules/utils/robots';
import { LOCALE_EN } from '@glass/env/modules/locales/constants';

const ROBOTS = 'robots';

type RobotsHeadProps = {
  content?: string;
};

/**
 * Robots Head Component to set the robots meta tag.
 * @param {string} content
 * @returns Robots Head Component
 */

function RobotsHead({ content: contentProp }: RobotsHeadProps) {
  const { locale } = useRouter();
  const content =
    process.env.NEXT_PUBLIC_ROBOTS ?? (locale && locale !== LOCALE_EN ? NOINDEX : contentProp);

  if (!content) {
    return null;
  }

  return (
    <Head>
      <meta key={ROBOTS} name={ROBOTS} content={content} />
    </Head>
  );
}

export default RobotsHead;
