import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { getFindManyCategoryOptions } from '@glass/rock/modules/taxonomy/getTaxonomyClientOptions';
import useTaxonomyClient from '@glass/rock/modules/taxonomy/useTaxonomyClient';

import {
  TAXONOMY_CLIENT,
  TAXONOMY_CLIENT_VERSION,
} from '@glass/common/modules/assignments/configs/taxonomyClient';
import useAssignmentIsVariation from '@glass/common/modules/assignments/useAssignmentIsVariation';
import useIsAssignmentReady from '@glass/common/modules/assignments/useIsAssignmentReady';
import { CategoryType, FindManyCategoryDocument } from '@glass/content/client';
import InlineCenterSkeleton from '@glass/web/components/Skeleton/InlineCenterSkeleton';

import FooterIndustryListItem from '@glass/shared/components/Layout/Footer/FooterIndustryListItem';
import FooterListItem from '@glass/shared/components/Layout/Footer/FooterListItem';
import FooterTitle from '@glass/shared/components/Layout/Footer/FooterTitle';
import BrowseLink from '@glass/shared/components/Link/BrowseLink';
import useResumeWords from '@glass/shared/modules/content/staticVariations/resumeWords/useResumeWords';
import usePageSeed from '@glass/shared/modules/content/usePageSeed';
import industriesQuery from '@glass/shared/modules/jobTitles/queries/industries.graphql';
import { RANDOM_ORDER_BY } from '@glass/shared/modules/resources/orderBys';
import useLoadingResources from '@glass/shared/modules/resources/useLoadingResources';

const seeMoreTrackProps = { label: 'footer-see-more' };
function FooterIndustryList({ numItems }) {
  const seed = usePageSeed();
  const resumeWords = useResumeWords();
  const loadingIndustries = useLoadingResources(numItems);

  const isAssignmentReady = useIsAssignmentReady();
  const isTaxonomyClient = useAssignmentIsVariation(TAXONOMY_CLIENT);
  const taxonomyClient = useTaxonomyClient();
  const skip = !isAssignmentReady || !seed;

  const taxonomyOptions = useMemo(
    () =>
      getFindManyCategoryOptions({
        client: taxonomyClient,
        skip,
        variables: {
          take: numItems,
          randomOrderBy: true,
          seed,
          where: {
            type: {
              equals: CategoryType.Industry,
            },
          },
          version: TAXONOMY_CLIENT_VERSION,
        },
        ssr: false,
      }),
    [taxonomyClient, skip, seed, numItems],
  );

  const queryOptions = {
    variables: {
      orderBy: RANDOM_ORDER_BY,
      first: numItems,
      seed,
    },
    skip,
    ssr: false,
  };

  const query = isTaxonomyClient ? FindManyCategoryDocument : industriesQuery;
  const options = isTaxonomyClient ? taxonomyOptions : queryOptions;
  const dataKey = isTaxonomyClient ? 'findManyCategory' : 'industries';

  const { data, loading } = useQuery(query, options);

  const isLoading = !isAssignmentReady || loading;

  return (
    <>
      <FooterTitle>Find your {resumeWords.singular}</FooterTitle>
      <div>
        {((isLoading && !data?.[dataKey]?.length ? loadingIndustries : data?.[dataKey]) || []).map(
          (item) => {
            const { slug, width, name } = item || {};
            if (!slug) {
              return null;
            }
            return (
              <FooterIndustryListItem
                key={slug}
                name={isLoading ? <InlineCenterSkeleton inline width={width} /> : name}
                slug={!isLoading ? slug : null}
              />
            );
          },
        )}
        <FooterListItem component={BrowseLink}>
          <BrowseLink trackProps={seeMoreTrackProps}>
            <b>Browse {resumeWords.pluralAbbreviatedCapitalized}</b>
          </BrowseLink>
        </FooterListItem>
      </div>
    </>
  );
}

FooterIndustryList.propTypes = {
  numItems: PropTypes.number,
};

FooterIndustryList.defaultProps = {
  numItems: 4,
};

export default React.memo(FooterIndustryList);
